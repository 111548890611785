import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const colors = {
    light: 'rgba(255, 233, 201, 1)',
    main: 'rgba(247, 97, 0, 1)',
    dark: 'rgba(219, 86, 0, 1)',
    bright: 'rgba(255, 140, 31, 1)'
};

declare module '@material-ui/core/styles/createPalette' {
    interface TypeBackground {
        subtle: string;
        dark?: string;
    }
    interface TypeText {
        soft: string;
    }
}

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            common: {
                black: '#000',
                white: '#fff'
            },
            background: {
                paper: '#fff',
                default: 'rgba(255, 255, 255, 1)',
                subtle: 'rgba(255, 183, 137, 0.09)',
                dark: 'rgb(46 46 46)'
            },
            primary: {
                main: colors.main,
                light: colors.light,
                dark: colors.dark,
                contrastText: '#fff'
            },
            secondary: {
                main: colors.bright,
                light: 'rgb(250 244 235)',
                contrastText: '#b3b3b3'
            },
            error: {
                main: '#f44336',
                contrastText: '#fff'
            },
            text: {
                primary: '#333',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                soft: '#505160',
                hint: 'rgba(0, 0, 0, 0.38)'
            }
        }
    })
);

export const gradients = {
    main: `linear-gradient(90deg, ${colors.main} 0%, ${colors.bright} 40%, ${colors.bright} 70%, ${colors.main} 100%)`,
    circular: `radial-gradient(circle, ${colors.dark} 0%, ${colors.main} 70%, ${colors.bright} 100%)`,
    background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.subtle} 100%)`
};
