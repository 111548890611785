import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import UpdateIcon from '@material-ui/icons/Update';
import clsx from 'clsx';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { displayCancellation } from '../../utils/functions/common';
import { Product } from '../../utils/models/products';
import { BookingStore } from '../../utils/stores/BookingStore';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import BookingModal from '../bookings/BookingModal';
import PageTitle from '../coreLayout/PageTitle';
import KeyDetailWidget from './KeyDetailWidget';
import KeySellingPointsWidget from './KeySellingPoints';
import ProductImageGallery from './ProductImageGallery';
import { DisplayText, Sales } from './TextAndPriceDisplay';

export const displayProductStyles = makeStyles((theme) => ({
    productContainer: {
        paddingBottom: 25,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    productDesc: {
        [theme.breakpoints.only('xs')]: {
            padding: '0 20px',
            border: 'none',
            textAlign: 'left'
        },
        padding: 20,
        borderRadius: 10
    },
    pageTitle: {
        [theme.breakpoints.only('xs')]: {
            padding: '2rem 20px 0'
        }
    },
    divider: {
        textAlign: 'left',
        marginLeft: 10,
        width: '90%',
        marginTop: 30,
        marginBottom: 30,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    innerContainer: {
        [theme.breakpoints.only('xs')]: {
            padding: 0
        }
    },
    topContainer: {
        flexDirection: 'column',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column-reverse'
        }
    },
    featureSection: {
        paddingBottom: 30,
        maxWidth: 'calc(80% + 30px)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            padding: '20px 0 20px'
        },
        [theme.breakpoints.only('xs')]: {
            padding: '5px 20px 10px'
        }
    },
    featureGridItems: {
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        padding: '0 15px',
        height: '100%'
    },
    lowerContainer: {
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    cancellationSection: {
        paddingBottom: 30,
        maxWidth: 'calc(80% + 30px)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            paddingBottom: 20
        },
        [theme.breakpoints.only('xs')]: {
            padding: '0 0 20px'
        }
    },
    cancellationtText: {
        color: theme.palette.text.soft
    }
}));

interface ProductDisplayProps {
    readonly tour: Product;
}

export default function ProductDisplay({ tour }: ProductDisplayProps) {
    const classes = displayProductStyles();
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);

    const currentSales = () => {
        const sales =
            productController?.product?.sales &&
            productController.product.sales.filter((sale) => !moment().isAfter(sale.endDate));
        const returnValue = sales && sales.length > 0 ? sales : undefined;
        return returnValue;
    };

    useEffect(() => {
        return () => {
            bookingController.clearController();
            productController.clearController();
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${tour.name} | ${tour.titleSEO}`}</title>
                <meta name="description" content={tour.shortDescription} />
            </Helmet>
            <Container maxWidth="lg" className={classes.innerContainer}>
                <Grid container className={classes.topContainer}>
                    <Grid item xs={12} className={classes.pageTitle}>
                        <PageTitle title={tour.name} tagline={tour.tagline} />
                    </Grid>
                    <Grid item xs={12}>
                        <ProductImageGallery />
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.productContainer}>
                    <Grid item xs={12}>
                        <BookingModal />
                    </Grid>
                    {currentSales() && <Sales currentSales={currentSales} />}
                    <Grid container justifyContent="center" className={classes.featureSection}>
                        <Grid item xs={12} sm={6}>
                            <Grid item className={classes.featureGridItems}>
                                <KeyDetailWidget />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ height: '100%', width: '100%' }}>
                            <Grid item className={classes.featureGridItems}>
                                <KeySellingPointsWidget />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={clsx(classes.lowerContainer, classes.cancellationSection)}>
                        <Paper variant="outlined" className={classes.productDesc} style={{ width: '100%' }}>
                            <Grid container style={{ marginBottom: 10 }}>
                                <Grid item xs="auto" style={{ marginRight: 12, paddingTop: 2 }}>
                                    <EventBusyIcon color="primary" />
                                </Grid>
                                <Grid container direction="column" item xs={true}>
                                    <Typography style={{ fontWeight: 500, marginBottom: 8, fontSize: 18 }}>
                                        Free Cancellation
                                    </Typography>
                                    <Typography style={{ marginBottom: 12 }} className={classes.cancellationtText}>
                                        Cancel or change your booking up to{' '}
                                        {displayCancellation(productController?.product?.cancellationPeriod)} prior to
                                        departure.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs="auto" style={{ marginRight: 12, paddingTop: 2 }}>
                                    <UpdateIcon color="primary" />
                                </Grid>
                                <Grid container direction="column" item xs={true}>
                                    <Typography style={{ fontWeight: 500, marginBottom: 8, fontSize: 18 }}>
                                        Instant Booking
                                    </Typography>
                                    <Typography className={classes.cancellationtText}>
                                        Book online, with live availability, and instant confirmation.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid container className={classes.lowerContainer}>
                        <Grid item xs={12}>
                            <Paper variant="outlined" className={classes.productDesc}>
                                {productController.product?.tagline && (
                                    <Typography variant="h5" style={{ fontWeight: 500, paddingBottom: 20 }}>
                                        {productController.product.tagline}
                                    </Typography>
                                )}
                                <DisplayText text={tour.longDescription} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
