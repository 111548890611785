import { Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PageTitle from '../components/coreLayout/PageTitle';
import FleetItemDisplay from '../components/features/FleetItemDisplay';

export interface Facts {
    readonly speed: string;
    readonly seats: string;
    readonly power: string;
}

export interface FleetImage {
    readonly src: string;
    readonly fallback: string;
    readonly alt: string;
}

export interface FleetItem {
    readonly name: string;
    readonly description: string;
    readonly keyFacts: Facts;
    readonly images: FleetImage[];
}

const fleetPageStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        paddingBottom: 40,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            paddingBottom: 20
        }
    },
    backgroundTransition: {
        background: ` -webkit-linear-gradient(
            270deg
            , ${theme.palette.background.default} 0%, ${theme.palette.background.subtle} 100%)`
    },
    backgroundColor: {
        backgroundColor: theme.palette.background.subtle,
        [theme.breakpoints.only('xs')]: {
            backgroundColor: 'unset'
        }
    }
}));

export default function FleetPage() {
    const classes = fleetPageStyles();
    const fleet: FleetItem[] = [
        {
            name: 'Cessna Grand Caravan',
            description: `The Grand Caravan is a powerful and versatile aircraft. 
                The Caravan can be configured with up to 13 seats, and in a scenic flight configuration, every passenger has a window seat. 
                In a freight configuration, the aircraft can carry full pallets, with loading through the rear cargo door.
                With a turbo-prop engine, the Caravan is known for reliability and safety.
                The Caravan is used by GSL Aviation in both Airlie Beach and Cairns, for a variety of work including scenic flights, passenger transport, and freight charter.
                From the Torres Strait, to the Gulf, and around Central Queensland, the Caravan is a favourite amongst pilots and passengers.`,
            keyFacts: {
                speed: '150 knots',
                seats: 'Up to 13 passengers',
                power: '675hp PT6 turbo-prop'
            },
            images: [
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/aircraftCharterCairns.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/aircraftCharterCairns.JPG',
                    alt: 'Cessna Caravan on a scenic flight in the Whitsundays, Airlie Beach'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsCharterAircraftCapeYork.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsCharterAircraftCapeYork.JPG',
                    alt: 'The Cessna Caravan Aircraft on Charter Cape York, ex Cairns'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsFreightAircraftCharter.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsFreightAircraftCharter.jpg',
                    alt: 'Cessna Caravan airfcraft loading freight on charter from Cairns'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cessnaCaravanWhitsundayScenicFlight.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cessnaCaravanWhitsundayScenicFlight.jpg',
                    alt: 'Cessna Caravan flying on a scenic flight in the Whitsundays, Airlie Beach'
                }
            ]
        },
        {
            name: 'GA8 Airvan',
            description: `The Airvan is a dependable workhorse, manufactured here in Australia. 
                GSL Aviation has been operating the GA8 for more than a decade, and it continues to be a well-suited and reliable aircraft.
                Fitted with a cargo pod, it's ideal for short island hops and freight charter.
                With guaranteed window seats, and great visibility, it's a popular aircraft with our passengers too. 
                GSL Aviation operate the Airvan from both Cairns and Airlie Beach.`,
            keyFacts: {
                speed: '110 knots',
                seats: 'Up to 7 passengers',
                power: '300hp IO-540 Lycoming Engine'
            },
            images: [
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/airvanAirlieBeachTour.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/airvanAirlieBeachTour.jpg',
                    alt: 'Airvan on a scenic flight in the Whitsundays, from Airlie Beach'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/airvanWhitsundayScenicFlight.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/airvanWhitsundayScenicFlight.jpg',
                    alt: 'GA8 Airvan on charter with GSL Aviation'
                }
            ]
        },
        {
            name: 'Cessna 206',
            description: `The Cessna 206 has been manufactured by Cessna since the 1960's, and is still sold as a new aircraft today. 
            The 206 is a reliable and versatile aircraft, used in various commerical capacities around the world.
            GSL Aviation have operated the Cessna 206 since 2011, both on floats and wheels.
            With seating for up to 5 passengers, and plenty of payload, the 206 is a proven performer.
            The aircraft offers guaranteed window seats for all passengers.
            When chartered, the rear doors can be removed for photography and video work, and it has been used for filming in the Whitsundays and Cairns numerous times.`,
            keyFacts: {
                speed: '130 knots',
                seats: 'Up to 5 passengers',
                power: '300hp IO-550 Continental Engine'
            },
            images: [
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsTourScenicFlightAircraft.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsTourScenicFlightAircraft.JPG',
                    alt: 'Cessna 206 at Cairns Airport, ready to depart on a scenic flight'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cessna206CairnsScenicFlights.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cessna206CairnsScenicFlights.JPG',
                    alt: 'Cairns scenic flight aircraft. Cessna 206 operated by GSL Aviation'
                }
            ]
        },
        {
            name: 'Piper Chieftain',
            description: `The Chieftain is a 10 seat twin engine aircraft.
            GSL operate the Chieftain from our base in Cairns, offering charter services to Cape York and the Gulf.
            The chieftain is maintained for Instrument Flight (IFR), allowing the aircraft to operate in inclement weather, or at night.
            Primary used for government and corporate clients, the Chieftain is also suited for passenger transfers to destinations such as Lizard Island, Cooktown, Weipa, and Karumba.
            The aircraft cruises at 170 knots, and is a fast and cost-effective twin engine charter aircraft.`,
            keyFacts: {
                speed: '170 knots',
                seats: 'Up to 9 passengers',
                power: '2x TIO-540 Lycoming Engines'
            },
            images: [
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsCharterAircraft.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/cairnsCharterAircraft.JPG',
                    alt: 'Piper Chieftain aircraft ready for charter at Cairns Airport'
                },
                {
                    src: 'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/twinEngineCharterAircraftCairns.webp',
                    fallback:
                        'https://gslgroup-media.s3.ap-southeast-2.amazonaws.com/gslaviation/aircraft/twinEngineCharterAircraftCairns.JPG',
                    alt: 'Aircraft charter from Cairns Airport - twin engine IFR Chieftain'
                }
            ]
        }
    ];

    return (
        <>
            <Container maxWidth="lg">
                <PageTitle title="Our Fleet" tagline="Your ride to adventure in North Qld" />
            </Container>
            <div style={{ paddingTop: 38 }}>
                {fleet.map((item, i) => (
                    <div
                        key={`fleetItem${i + 1}`}
                        className={clsx(classes.container, {
                            [classes.backgroundTransition]: i === 0,
                            [classes.backgroundColor]: (i + 1) % 2 === 0
                        })}
                    >
                        <FleetItemDisplay asset={item} reverse={(i + 1) % 2 === 0} />
                    </div>
                ))}
            </div>
        </>
    );
}
