import { Grid, List, ListItem, ListItemText, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { widgetStyles } from './widgets/widgetStyles';

export default function KeySellingPointsWidget() {
    const classes = widgetStyles();
    const theme = useTheme();
    const mediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const smallDown = useMediaQuery(theme.breakpoints.down('sm'));
    const productController = useContext(ProductsStore);

    return (
        <Paper variant="outlined" className={clsx(classes.paper, classes.paperRight)}>
            <Grid container direction="column">
                <Typography variant="h5" style={{ fontWeight: 500, paddingBottom: 5 }}>
                    Reasons to Love It
                </Typography>
                <List>
                    {productController.product?.usp &&
                        productController.product.usp.map((item: any, i: any) => (
                            <ListItem
                                key={`usp${i}`}
                                style={{
                                    color: theme.palette.text.soft,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    paddingLeft: mediumDown ? 0 : 16
                                }}
                            >
                                <div style={{ paddingRight: 30, display: 'flex' }}>
                                    <FavoriteBorderOutlinedIcon color="primary" fontSize="small" />
                                </div>
                                <ListItemText>{item}</ListItemText>
                            </ListItem>
                        ))}
                </List>
            </Grid>
        </Paper>
    );
}
