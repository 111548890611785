import { Box, Container, Grid, ListItem, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { productURL } from '../../utils/functions/common';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import { baseProducts } from '../../utils/stores/productCodes';
import ContactDetails from '../coreLayout/ContactDetails';

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        paddingTop: 20,
        paddingBottom: 10,
        backgroundColor: theme.palette.background.dark
    },
    popularLinks: {
        margin: '30px 20px 0',
        backgroundColor: 'inherit'
    },
    linksContainer: {
        paddingTop: 10,
        PaddingBottom: 10,
        display: 'flex',
        flexWrap: 'wrap'
    },
    separator: {
        display: 'block',
        height: 2,
        backgroundColor: 'rgb(250 244 235)',
        content: '',
        width: 150,
        marginBottom: 10
    },
    linkItems: {
        background: theme.palette.primary.light,
        color: theme.palette.background.dark,
        padding: '8px 13px',
        borderRadius: 10,
        fontWeight: 400,
        fontSize: 17,
        width: 'fit-content',
        marginRight: 10,
        marginBottom: 15,
        lineHeight: '130%'
    },
    externalLinks: {
        '& .MuiTypography-body1': {
            fontWeight: 400,
            fontSize: '1.1rem',
            color: 'white'
        }
    }
}));

export default function Footer() {
    const classes = useStyles();
    const productController = useContext(ProductsStore);

    const linkButtons = [
        {
            text: 'Terms & Conditions',
            link: '/termsandconditions'
        },
        {
            text: 'Our Fleet',
            link: '/fleet'
        }
    ];

    const productLinks =
        !productController.isLoading &&
        baseProducts
            .filter((p) => p.tags.includes('featureTile') || p.tags.includes('popular'))
            .map(
                (p) =>
                    productController.products
                        .filter((prod) => prod.productCode === p.productCode)
                        .map((x) => ({ text: x.name, link: productURL(x) }))[0]
            );

    const ListItemLink = (props: any) => {
        return <ListItem button component="a" {...props} />;
    };

    return (
        <div className={classes.footerContainer}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.popularLinks}>
                            <Typography variant="h5" style={{ marginBottom: 20, color: 'white' }}>
                                Popular Links
                            </Typography>
                            <Box className={classes.linksContainer}>
                                {linkButtons.concat(productLinks).map((link, i) => (
                                    <Typography
                                        variant="h6"
                                        display="inline"
                                        className={classes.linkItems}
                                        component={Link}
                                        to={link.link || '/'}
                                        key={`button${i}`}
                                    >
                                        {link.text}
                                    </Typography>
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContactDetails
                            location="Airlie Beach"
                            mapLink="https://goo.gl/maps/p1o1UyWCfPQnseFK8"
                            address="384 Shute Harbour Road, Airlie Beach QLD 4802"
                            phone="07 4015 4191"
                            email="info@gslaviation.com.au"
                            hideSocial
                            darkBackground
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContactDetails
                            location="Cairns"
                            mapLink="https://goo.gl/maps/LQDRrKGD5kQnmtG39"
                            address="83 Royal Flying Doctor St, Cairns QLD 4870"
                            phone="07 4015 4191"
                            email="cairns@gslaviation.com.au"
                            hideSocial
                            darkBackground
                        />
                    </Grid>
                    <Grid container item xs={12} style={{ paddingTop: 40, paddingLeft: 20, paddingBottom: 15 }}>
                        <Typography style={{ color: 'white' }}>
                            {'Copyright © '} GSL Aviation {new Date().getFullYear()}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
