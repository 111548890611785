import { Extras, PriceOption } from './products';

export interface Payment {
    readonly amount?: number;
    readonly date?: string;
    readonly paymentId?: string;
    readonly type?: string;
}

export interface Subtotals {
    readonly [key: string]: number;
}

export interface Pricing {
    readonly subtotals: Subtotals;
    readonly [key: string]: number | Subtotals;
}

export interface Tour {
    readonly pickUpLocation?: string;
    readonly pickUpTime?: string;
    readonly productCode: string;
    readonly productName: string;
    readonly startTimeLocal: string;
}

export interface DepartureDateTime {
    local: string;
    UTC: string;
}

export interface Customer {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly phone: string;
}

export interface Participant {
    fields?: ParticipantInfoField[];
}

export enum ParticipantInfoFieldType {
    TEXT = 'text',
    DATE = 'date',
    BOOLEAN = 'boolean',
    NUMBER = 'number',
    LIST = 'list'
}

export interface ParticipantInfoField {
    readonly fieldType?: ParticipantInfoFieldType;
    readonly label?: string;
    readonly value?: string;
    readonly listOptions?: string[];
}

export enum GSLEntities {
    AVIATION = 'aviation',
    MARINE = 'marine',
    GSL_WHITSUNDAYS = 'gsl_whitsundays',
    SEAPLANES = 'air_whitsunday'
}

interface BookingResponse {
    readonly success: boolean;
    readonly refNum?: string;
    readonly error?: any;
}

export interface Booking {
    readonly customer?: Customer;
    readonly entity: GSLEntities;
    readonly participants?: Participant[];
    readonly quantities?: PriceOption[];
    readonly totalQuantity?: number;
    readonly pricing?: Pricing;
    readonly tour?: Tour;
    readonly payment?: Payment;
    readonly operatorRef?: any;
    readonly totalAmount?: number;
    readonly comment?: string;
    readonly extras?: Extras[];
    readonly bookingResponse?: BookingResponse;
    readonly bookingComplete: boolean;
    readonly isPackage?: boolean;
    readonly packageProductNames?: string[];
    readonly packageBooking?: Booking;
}

export const EMPTY_BOOKING: Booking = {
    customer: {
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
    },
    extras: [],
    totalAmount: 0,
    entity: GSLEntities.AVIATION,
    bookingComplete: false,
    comment: 'GSL Aviation website booking.'
};

export const EMPTY_PACKAGE_BOOKING: Booking = {
    customer: {
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
    },
    extras: [],
    totalAmount: 0,
    entity: GSLEntities.AVIATION,
    bookingComplete: false,
    comment: 'GSL Aviation website booking.'
};
