import React, { useContext, useState, useEffect } from 'react';
import { map, sum } from 'lodash';
import { Typography, IconButton, Dialog, DialogContent, Grid, Hidden } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CustomerDetails from './formSections/CustomerDetails';
import Payments from './formSections/PaymentSection';
import Swal from 'sweetalert2';
import { BookingStore } from '../../utils/stores/BookingStore';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import PickUpSection from './formSections/PickUpSection';
import ParticipantsInfoStep from './formSections/ParticipantInfoFields';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import CheckAvailability from './formSections/AvailabilitySections';
import StepperButtons from './formSections/StepperButtons';
import LargeStepper from './LargeStepper';
import { gradients } from '../../utils/theme';
import BookingFormSummary from './BookingFormSummary';
import useScript from 'react-script-hook';
import BookingFormSummaryAccordion from './BookingFormSummaryAccordion';
import ExtraSelectionStep from './formSections/ExtraSelectionStep';
import { useHistory } from 'react-router-dom';
import IsMountedWrapper from '../../utils/functions/isMountedWrapper';

const bookingFormStyles = makeStyles((theme: Theme) => ({
    bookingContent: {
        padding: '0 32px 32px 32px',
        maxHeight: '90vh',
        minHeight: 600,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.only('xs')]: {
            padding: 15,
            maxHeight: '80vh'
        },
        overflowY: 'visible'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 20px 20px 32px',
        [theme.breakpoints.only('xs')]: {
            padding: '10px 10px 10px 22px'
        }
    },
    titleText: {
        '-webkit-background-clip': 'text',
        color: 'transparent',
        backgroundImage: gradients.circular
    },
    swalButton: {
        backgroundColor: theme.palette.primary.main + '!important'
    },
    contentAndSummary: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row'
        }
    },
    dialog: {
        '& .MuiDialog-container': {
            width: '100%',
            borderRadius: 8
        },
        '& .MuiDialog-paper': {
            maxHeight: '90vh',
            [theme.breakpoints.only('xs')]: {
                margin: 0,
                maxWidth: 'calc(100% - 20px)',
                maxHeight: '80vh'
            }
        },
        background:
            'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(252,252,252,1) 10%, rgba(248,248,248,1) 88%, rgba(248,248,248,1) 100%)'
    }
}));

const moment = require('moment');

export default function BookingModal() {
    const classes = bookingFormStyles();
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const bookingStep = bookingController.step;
    const isMounted = IsMountedWrapper();
    const history = useHistory();
    const hasExtras = productController?.product?.extras.length > 0;
    const localBookingString = process.env.REACT_APP_LOCAL_BOOKING;
    useScript({ src: 'https://secure.ewaypayments.com/scripts/eCrypt.min.js' });

    useEffect(() => {
        if (bookingController.booking?.bookingResponse?.success) {
            const ref = bookingController.booking.bookingResponse.refNum;
            bookingController.completedBookings.setData(
                {
                    booking: bookingController.booking,
                    package: bookingController.booking?.isPackage && bookingController.packageBooking.data
                },
                isMounted
            );
            localStorage.setItem(
                localBookingString,
                JSON.stringify({
                    booking: bookingController.booking,
                    package: bookingController.booking?.isPackage && bookingController.packageBooking.data
                })
            );
            setOpen(false);
            history.push(`/confirmation/${ref}`);
        }
    }, [bookingController.booking.bookingResponse]);

    const swalAlert = (text: string) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            iconColor: theme.palette.primary.main,
            text: text,
            customClass: {
                confirmButton: classes.swalButton
            }
        });
    };

    const handleClickOpen = () => {
        const sumOfFares = sum(
            bookingController.booking.quantities && bookingController.booking.quantities.map((x) => x.value)
        );
        const errorType = {
            time: !bookingController.booking?.tour?.startTimeLocal,
            seats:
                sumOfFares >
                (productController?.availability?.sessions.find((session) =>
                    moment(session?.startTime).isSame(bookingController.booking?.tour?.startTimeLocal)
                )?.seatsRemaining || 0),
            fares: sum(map(bookingController.booking.quantities, (x) => x.value)) === 0
        };
        bookingController.reset();
        Object.values(errorType).includes(true)
            ? swalAlert('Please make sure you have selected a time for your tour, and a valid booking/fare option')
            : setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        bookingController.reset();
    };

    const DialogTitle = (props: any) => {
        const { classes, onClose, children } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="h5" className={classes.titleText}>
                    {children}
                </Typography>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon style={{ color: 'rgb(0 0 0 / 23%)' }} />
                </IconButton>
            </MuiDialogTitle>
        );
    };

    const getStepContent = () => {
        if (hasExtras) {
            switch (bookingStep) {
                case 0:
                    return <CustomerDetails />;
                case 1:
                    return <ParticipantsInfoStep />;
                case 2:
                    return <ExtraSelectionStep />;
                case 3:
                    return <PickUpSection />;
                case 4:
                    return <Payments />;
            }
        } else {
            switch (bookingStep) {
                case 0:
                    return <CustomerDetails />;
                case 1:
                    return <ParticipantsInfoStep />;
                case 2:
                    return <PickUpSection />;
                case 3:
                    return <Payments />;
            }
        }
    };

    return (
        <>
            <CheckAvailability handleClickOpen={handleClickOpen} />
            <Dialog open={open} aria-label="booking-form" maxWidth="lg" scroll="body" className={classes.dialog}>
                <DialogTitle onClose={handleClose} classes={classes}>
                    Booking
                </DialogTitle>
                <DialogContent className={classes.bookingContent}>
                    <LargeStepper numberOfSteps={hasExtras ? 5 : 4} />
                    <Hidden lgUp>
                        <BookingFormSummaryAccordion />
                    </Hidden>
                    <Grid container className={classes.contentAndSummary}>
                        <Grid item xs={12} lg={4}>
                            <Hidden mdDown>
                                <BookingFormSummary />
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            {getStepContent()}
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: bookingController.booking.bookingComplete ? 'none' : 'unset' }}>
                                <StepperButtons numberOfSteps={hasExtras ? 5 : 4} />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

/* Surcharge Calcs:

let surcharge = (subtotals*.015).toFixed(2);
let total = (subtotals + Number(surcharge));
set(bookingData, 'subtotals', subtotals)
set(bookingData, 'surcharge', surcharge);
set(bookingData, 'totalAmount', total);

*/
