import { makeStyles } from '@material-ui/core/styles';
import { gradients } from '../../../utils/theme';

export const availabilityStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        [theme.breakpoints.only('xs')]: {
            padding: '0 30px'
        },
        [theme.breakpoints.only('xs')]: {
            padding: '0 20px'
        }
    },
    paper: {
        zIndex: 8,
        padding: '15px 25px 20px',
        width: '80%',
        '@media (max-width:999px)': {
            width: '100%'
        },
        borderRadius: 10,
        [theme.breakpoints.only('sm')]: {
            padding: '5px 10px 30px'
        },
        [theme.breakpoints.only('xs')]: {
            zIndex: 5,
            padding: 0
        }
    },
    menu: {
        width: 190
    },
    menuButton: {
        width: '90%',
        height: 54,
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 14.5,
        paddingRight: 14.5,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    menuItem: {
        width: 280,
        paddingRight: 2
    },
    selectFields: {
        width: '90%',
        '& .MuiOutlinedInput-root': {
            height: 54
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    loadContainer: {
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        height: 54,
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'center',
        borderStyle: 'solid',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'rgb(0 0 0 / 23%)'
    },
    title: {
        marginBottom: 15,
        width: '100%',
        textAlign: 'left',
        fontSize: 22,
        backgroundImage: gradients.main,
        '-webkit-background-clip': 'text',
        color: 'transparent',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 15
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '5%'
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: '5%'
        }
    },
    buttonContainer: {
        justifyContent: 'center',
        alignContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        },
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'center',
            marginTop: 25
        }
    },
    button: {
        width: '80%',
        height: 54,
        backgroundImage: gradients.circular,
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.only('xs')]: {
            margin: '30px 0'
        },
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    innerContainer: {
        [theme.breakpoints.only('xs')]: {
            alignItems: 'center',
            flexDirection: 'column'
        }
    },
    sectionItem: {
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignText: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            width: '90%'
        }
    },
    packageNote: {
        width: '100%',
        display: 'flex',
        marginTop: 20,
        [theme.breakpoints.only('sm')]: {
            padding: '0 2.5%'
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: 15,
            justifyContent: 'center',
            padding: 0
        }
    },
    packageNoteText: {
        color: theme.palette.text.soft,
        fontWeight: 400,
        fontSize: 17,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.3,
        paddingRight: '5%',
        [theme.breakpoints.only('xs')]: {
            paddingRight: 0
        }
    },
    calendarSize: {
        '& .MuiPopover-paper': {
            [theme.breakpoints.only('xs')]: {
                maxWidth: 'calc(100% - 10px)',
                overflowX: 'scroll',
                color: 'pink'
            }
        }
    },
    innerGridItem: {
        width: '100%',
        [theme.breakpoints.only('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}));
