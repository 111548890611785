import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import HeroSection from '../components/features/HeroSection';
import IconSection from '../components/features/IconSection';
import InstagramImages from '../components/features/InstagramImages';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { ProductCategories } from '../utils/models/products';
import { baseProducts } from '../utils/stores/productCodes';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 38,
        color: theme.palette.primary.main,
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            marginTop: 40
        }
    },
    body: {
        fontSize: 18,
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    backgroundContrast: {
        background: ` -webkit-linear-gradient(rgba(255, 255, 255, 1), ${theme.palette.background.subtle})`,
        width: '100%'
    }
}));

const HomePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const heroTiles = baseProducts
        .map((prod) => prod.tags.includes('featureTile') && prod.productCode)
        .filter((res) => res);

    return (
        <>
            <Helmet>
                <title>GSL Aviation | Tours, Scenic Flights &amp; Air Charter | Airlie Beach &amp; Cairns</title>
                <meta
                    name="description"
                    content="GSL Aviation offers Day Tours, Scenic Flights &amp; Air Charter. Explore and travel from Airlie Beach in the Whitsundays, or Cairns Far North Queensland. Daily flights and helicopter tours over the Great Barrier Reef"
                />
            </Helmet>
            <HeroSection productCodes={heroTiles} />
            <div className={classes.backgroundContrast}>
                <Container maxWidth="lg" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" className={classes.title}>
                        Tours in Cairns
                    </Typography>
                    <Typography className={classes.body} align="center">
                        Departing from Cairns International Airport, our scenic flights enjoy a great view of the city,
                        and the North Qld coastline. It&apos;s only a short flight to the Great Barrier Reef, where you
                        can get a birds-eye view of one of the natural wonders of the world. Explore our flights, or
                        package products taking you from the Reef to the Rainforest.
                    </Typography>
                </Container>
            </div>
            <ProductDisplayGrid backgroundColor category={ProductCategories.CAIRNS} tag={'popular'} />
            <Container maxWidth="lg">
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.title} align="center" style={{ marginTop: 40 }}>
                            Airlie Beach Tours
                        </Typography>
                    </Grid>
                    <Typography className={classes.body} align="center">
                        Explore the Great Barrier Reef &amp; Whitsunday Islands. GSL Aviation offer a range of tours in
                        Airlie Beach, operated by our partner providers. Cruise the Whitsundays, dive, snorkel, or fly
                        over it all! We have a range of tours to suit your bucket list.
                    </Typography>
                </Grid>
            </Container>
            <ProductDisplayGrid category={ProductCategories.AIRLIE} tag={'popular'} />
            <IconSection />
            <InstagramImages />
        </>
    );
};

export default HomePage;
