import React from 'react';

export interface DefaultProviderProps {
    children: React.ReactNode;
}

export function createStore<V>(Store: React.Context<V | undefined>, children: React.ReactNode, value: V) {
    return <Store.Provider value={value}>{children}</Store.Provider>;
}

export function createStoreContext<T>() {
    return React.createContext<T | undefined>(undefined);
}
