import { ceil, floor, toLower } from 'lodash';
import { Product } from '../models/products';
import { routes } from '../PageRouter';
import { baseProducts } from '../stores/productCodes';

export const convertToSearchableName = (productName) => {
    // eslint-disable-next-line
    return toLower(productName).replace(/[\s`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
};

export const productURL = (product: Product) => {
    const url = routes.products(product.category, convertToSearchableName(product.name));
    return url;
};

const minutesInOneDay = 1440;

export const minutesToDuration = (minutes: number | undefined): Duration => {
    if (minutes === undefined) {
        return {};
    }
    const days = Math.floor(minutes / minutesInOneDay);
    const left = minutes % minutesInOneDay;
    const hours = Math.floor(left / 60);
    minutes = left % 60;
    return { days, hours, minutes };
};

export const getDurationStr = (minutesEntry) => {
    const duration = minutesToDuration(minutesEntry || 0);
    const days = duration.days ? (duration.days === 1 ? duration.days + ' Day' : duration.days + ' Days') : '';
    const hours = duration.hours ? (duration.hours === 1 ? duration.hours + ' Hour' : duration.hours + ' Hours') : '';
    const minutes = duration.minutes
        ? duration.days === 1
            ? duration.minutes + ' Minute'
            : duration.minutes + ' Minutes'
        : '';
    return `${days} ${hours} ${minutes}`;
};

export const orderProducts = (productArray: Product[]) => {
    const index =
        productArray && baseProducts.map((prod) => productArray.findIndex((p) => p?.productCode === prod?.productCode));
    const newOrder = index.map((num) => productArray[num]);
    return newOrder;
};

export const strToAmount = (str) => Math.round(Number(str) * 100);
export const amountUiFormat = (amount) => ceil(floor((amount || 0) / 100, 2), 2);
export const amountUiStr = (amount) => ceil(floor(amount || 0, 2), 2).toFixed(2);

export const displayCancellation = (days: number) => {
    const display = days === 1 ? '24 hours' : days === 2 ? '48 hours' : `${days} days`;
    return display;
};
