import { Container, Typography } from '@material-ui/core';
import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { ProductCategories } from '../utils/models/products';
import { categoryStyles } from './AirlieTours';

export default function CairnsTours() {
    const classes = categoryStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>GSL Aviation | Cairns Tours, Packages &amp; Scenic Flights</title>
                <meta
                    name="description"
                    content="Expolore Cairns and the Great Barrier Reef with GSL Aviation. Daily scenic flights, day trips, &amp; package tours. See Green Island and the Great Barrier Reef."
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle
                    title="Cairns Tours"
                    tagline="Daily departures from Cairns Airport. See the Great Barrier Reef &amp; Green Island from above"
                />
            </Container>
            <Container
                maxWidth="lg"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}
            >
                <Typography variant="h4" className={classes.title}>
                    Fly above it all
                </Typography>
                <Typography className={classes.body}>
                    Explore our range of scenic flights, packages, and extended tours. Our range of products offer a
                    great variety of experiences, in the beautiful North Qld tropics.
                </Typography>
            </Container>
            <ProductDisplayGrid category={ProductCategories.CAIRNS} />
            <div style={{ marginBottom: 40, width: '100%' }} />
        </>
    );
}
