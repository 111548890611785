import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '70vh',
        textAlign: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    }
}));

export default function PageNotFound() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Typography variant="h4" color="primary">
                404 Error | Page Not Found
            </Typography>
            <Typography>Try using the menu to navigate to our other pages.</Typography>
        </div>
    );
}
