import { floor, map } from 'lodash';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import { Booking } from '../models/bookings';
import { baseProducts } from '../stores/productCodes';

const adList = baseProducts.map((p) => ({
    entity: p.entity,
    tourCode: p.productCode,
    name: p.name
}));

export const checkConversions = async (booking: Booking) => {
    map(adList, (ad) => {
        const eventParams = {
            currency: 'AUD',
            transaction_id: booking?.operatorRef || `${booking.entity} at ${moment().utcOffset(10).toISOString(true)}`,
            value: floor(booking.totalAmount, 2),
            items: booking.quantities
                .filter((q) => q.value > 0)
                .map((q) => ({
                    item_variant: q.label,
                    item_name: booking.tour.productName,
                    item_brand: ad.entity,
                    item_category: 'fare',
                    price: floor(q.price, 2),
                    quantity: q.value
                }))
                .concat(
                    booking.extras && booking.extras.length > 0
                        ? booking.extras
                              .filter((e) => e.quantity > 0)
                              .map((e) => ({
                                  item_variant: e.name,
                                  item_name: booking.tour.productName,
                                  item_brand: ad.entity,
                                  item_category: 'extra',
                                  price: floor(e.price / 100, 2),
                                  quantity: e.quantity
                              }))
                        : []
                )
        };
        booking.tour.productCode === ad.tourCode &&
            TagManager.dataLayer({ dataLayer: { event: 'purchase', ...eventParams } });
    });
};
