import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import ContactDetails from '../components/coreLayout/ContactDetails';
import PageTitle from '../components/coreLayout/PageTitle';

const useStyles = makeStyles((theme) => ({
    containerPadding: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    lowerContainerPadding: {
        paddingBottom: 50,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    mapBox: {
        width: '100%',
        height: 450,
        padding: 20,
        [theme.breakpoints.only('md')]: {
            height: 400
        },
        [theme.breakpoints.only('sm')]: {
            height: 500
        },
        [theme.breakpoints.only('xs')]: {
            height: 350
        }
    }
}));

export default function Contact() {
    const classes = useStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.remove();
        }
    }, []);
    return (
        <>
            <Helmet>
                <title>GSL Aviation | Airlie Beach &ampl; Cairns | Contact Us</title>
                <meta
                    name="description"
                    content="Contact GSL Aviation | Information about flights in Airlie Beach &amp; Cairns."
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle title="Contact Us" tagline="Contact our Airlie Beach or Cairns office" />
            </Container>
            <Container maxWidth="lg" className={classes.lowerContainerPadding}>
                <Grid container>
                    <Grid container item xs={12} md={6}>
                        <Grid item xs={12} md={6}>
                            <ContactDetails
                                location="Airlie Beach"
                                mapLink="https://goo.gl/maps/p1o1UyWCfPQnseFK8"
                                address="384 Shute Harbour Road, Airlie Beach QLD 4802"
                                phone="07 4015 4191"
                                email="info@gslaviation.com.au"
                                hideSocial
                            />
                        </Grid>
                        <Box className={classes.mapBox}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d935.6854954525979!2d148.71911427899587!3d-20.269532830757644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6bd83595d2b77729%3A0xf2d0bd39e5f9fc0f!2sGSL%20Whitsundays!5e0!3m2!1sen!2sau!4v1633417552595!5m2!1sen!2sau"
                                width="100%"
                                height="100%"
                                style={{ border: 0, borderRadius: 10 }}
                                allowFullScreen={false}
                                loading="lazy"
                            ></iframe>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} md={6}>
                        <Grid item xs={12} md={6}>
                            <ContactDetails
                                location="Cairns"
                                mapLink="https://goo.gl/maps/LQDRrKGD5kQnmtG39"
                                address="83 Royal Flying Doctor St, Cairns QLD 4870"
                                phone="07 4015 4191"
                                email="cairns@gslaviation.com.au"
                                hideSocial
                            />
                        </Grid>
                        <Box className={classes.mapBox}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3817.8755266446638!2d145.74669421603218!3d-16.882050888383326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x697865e7d583b86d%3A0x83f0ad265395f7cb!2sGSL%20Aviation!5e0!3m2!1sen!2sau!4v1633417077638!5m2!1sen!2sau"
                                width="100%"
                                height="100%"
                                style={{ border: 0, borderRadius: 10 }}
                                allowFullScreen={false}
                                loading="lazy"
                            ></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
