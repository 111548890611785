import React from 'react';
import CreditCardDetails from './CreditCardDetails';
import { Typography, Grid } from '@material-ui/core';
import { commonStyles } from './commonStyles';

const Payments = () => {
    const classes = commonStyles();

    return (
        <>
            <Grid container className={classes.container}>
                <Typography variant="h5" className={classes.title}>
                    Review &amp; Pay
                </Typography>
                <Typography className={classes.subtitle}>
                    Alright, let&apos;s do this! Please check your booking details, and make sure you&apos;ve selected
                    the right time, date, and booking options. Then all we need are some card details, and you&apos;re
                    on your way to adventure
                </Typography>
                <CreditCardDetails />
            </Grid>
        </>
    );
};

export default Payments;
