import { Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { sum } from 'lodash';
import moment from 'moment';
import React, { Fragment, useContext } from 'react';
import { amountUiStr } from '../../utils/functions/common';
import { BookingStore } from '../../utils/stores/BookingStore';
import { ProductsStore } from '../../utils/stores/ProductsStore';

const summaryStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
        paddingBottom: 8,
        [theme.breakpoints.only('xs')]: {
            padding: 15
        },
        borderRadius: 10,
        marginLeft: 10
    },
    detailContainer: {
        paddingLeft: 20
    },
    divider: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D9DBE9',
        width: '100%',
        height: 0
    },
    topDivider: {
        marginTop: 20,
        [theme.breakpoints.down('md')]: {
            marginTop: 5
        }
    },
    itemText: {
        fontSize: 17,
        lineHeight: '110%',
        marginBottom: 12,
        marginTop: 12,
        fontWeight: 300
    },
    dateTimeText: {
        fontSize: 17,
        lineHeight: '110%',
        marginBottom: 12,
        fontWeight: 300
    },
    qtyText: {
        fontSize: 15,
        lineHeight: '110%',
        fontWeight: 300,
        opacity: 0.65
    }
}));

interface SummaryProps {
    readonly isConfirmation?: boolean;
}

export function SummaryContent({ isConfirmation }: SummaryProps) {
    const bookingController = useContext(BookingStore);
    const classes = summaryStyles();
    const booking = isConfirmation ? bookingController.completedBookings.data.booking : bookingController.booking;
    const packageBooking = isConfirmation
        ? bookingController.completedBookings.data?.package
        : bookingController.packageBooking.data;
    const productController = useContext(ProductsStore);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <Grid container>
            {!isConfirmation && <div className={clsx(classes.divider, classes.topDivider)} />}
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    {booking?.isPackage ? (
                        <Typography
                            variant="h6"
                            style={{
                                paddingBottom: 0,
                                paddingTop: 2,
                                fontWeight: 400,
                                fontSize: isConfirmation ? (isXs ? 18 : 20) : 19
                            }}
                        >
                            {booking?.packageProductNames?.[0]}
                        </Typography>
                    ) : (
                        <Typography
                            variant="h6"
                            style={{
                                paddingBottom: 0,
                                fontWeight: 400,
                                fontSize: isConfirmation ? (isXs ? 18 : 20) : 22
                            }}
                        >
                            Booking Details
                        </Typography>
                    )}
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.dateTimeText}>Date of Travel</Typography>
                    <Typography className={classes.dateTimeText}>
                        {moment(booking.tour.startTimeLocal).utcOffset(10).format('D MMM YYYY')}
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.dateTimeText}>Tour Time</Typography>
                    <Typography className={classes.dateTimeText}>
                        {moment(booking.tour.startTimeLocal).utcOffset(10).format('h:mm a')}
                    </Typography>
                </Grid>
                {booking?.isPackage && (
                    <>
                        <Typography
                            variant="h6"
                            style={{
                                paddingBottom: 10,
                                marginTop: 10,
                                fontWeight: 400,
                                fontSize: isConfirmation ? (isXs ? 18 : 20) : 19
                            }}
                        >
                            {booking?.packageProductNames?.[1]}
                        </Typography>
                        <Grid container justifyContent="space-between">
                            <Typography className={classes.dateTimeText}>Date of Travel</Typography>
                            <Typography className={classes.dateTimeText}>
                                {isConfirmation
                                    ? moment(packageBooking.tour.startTimeLocal).utcOffset(10).format('D MMM YYYY')
                                    : !productController.availability.packageSessions.find((s) =>
                                          moment(s.startTime).isSame(packageBooking.tour.startTimeLocal)
                                      )
                                    ? 'TBA'
                                    : moment(packageBooking.tour.startTimeLocal).utcOffset(10).format('D MMM YYYY')}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Typography className={classes.dateTimeText}>Tour Time</Typography>
                            <Typography className={classes.dateTimeText}>
                                {isConfirmation
                                    ? moment(packageBooking.tour.startTimeLocal).utcOffset(10).format('h:mm a')
                                    : !productController.availability.packageSessions.find((s) =>
                                          moment(s.startTime).isSame(packageBooking.tour.startTimeLocal)
                                      )
                                    ? 'TBA'
                                    : moment(packageBooking.tour.startTimeLocal).utcOffset(10).format('h:mm a')}
                            </Typography>
                        </Grid>
                        <div className={classes.divider} style={{ marginTop: 10, marginBottom: 0 }} />
                    </>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: 12 }}>
                    <Typography variant="h6" style={{ fontWeight: 400, fontSize: 19 }}>
                        Fares
                    </Typography>
                </Grid>
                {booking?.quantities.map((item, i) => {
                    if (item.value > 0) {
                        return (
                            <Grid container justifyContent="space-between" key={`quantity${i}`}>
                                <Grid item xs={9} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography className={classes.itemText} style={{ marginBottom: 3 }}>
                                        {item.label}
                                    </Typography>
                                    <Typography className={classes.qtyText}>Quantity: {item.value}</Typography>
                                    <Typography className={classes.qtyText} style={{ marginTop: 3 }}>
                                        Price: ${item.price % 1 !== 0 ? amountUiStr(item.price) : item.price}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography className={classes.itemText}>
                                        ${' '}
                                        {(item.value * item.price) % 1 !== 0
                                            ? amountUiStr(item.value * item.price)
                                            : item.value * item.price}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    } else return null;
                })}
                {booking.extras.length > 0 && sum(booking.extras.map((e) => e.quantity)) > 0 && (
                    <>
                        <Grid item xs={12} style={{ paddingTop: 12 }}>
                            <Typography variant="h6" style={{ fontWeight: 400, fontSize: 19 }}>
                                Extras
                            </Typography>
                        </Grid>
                        {booking.extras.map((extra, i) => (
                            <Fragment key={`quantity${i}`}>
                                {extra.quantity > 0 && (
                                    <Grid container justifyContent="space-between" key={`quantity${i}`}>
                                        <Grid item xs={9} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography className={classes.itemText} style={{ marginBottom: 3 }}>
                                                {extra.name}
                                            </Typography>
                                            <Typography className={classes.qtyText}>
                                                Quantity: {extra.quantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography className={classes.itemText}>
                                                ${' '}
                                                {((extra.price * extra.quantity) / 100) % 1 !== 0
                                                    ? amountUiStr((extra.price * extra.quantity) / 100)
                                                    : (extra.price * extra.quantity) / 100}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Fragment>
                        ))}
                    </>
                )}
                <div className={classes.divider} style={{ marginTop: 22, marginBottom: 8 }} />
                <Grid container justifyContent="space-between">
                    <Typography className={classes.itemText} style={{ fontSize: 18, fontWeight: 400 }}>
                        Total
                    </Typography>
                    <Typography className={classes.itemText} style={{ fontSize: 18, fontWeight: 400 }}>
                        $ {amountUiStr(booking.totalAmount)}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default function BookingFormSummary() {
    const productController = useContext(ProductsStore);
    const classes = summaryStyles();

    return (
        <Paper className={classes.paper} style={{ maxHeight: 760, overflowY: 'auto' }}>
            <Grid container>
                <Grid item xs="auto">
                    <img
                        src={productController.product.photos[0].thumbnailURL}
                        alt="product booking thumbnail"
                        style={{ objectFit: 'cover', height: 100, width: 100, borderRadius: 10 }}
                    />
                </Grid>
                <Grid item xs={true}>
                    <Grid container className={classes.detailContainer}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 400, fontSize: 22, lineHeight: '115%', paddingBottom: 10 }}
                            >
                                {productController.product.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.itemText}
                                style={{ fontSize: 16, marginBottom: 0, opacity: 0.9 }}
                            >
                                {productController.product.tagline}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SummaryContent />
        </Paper>
    );
}
