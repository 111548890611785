import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StepperStoreProvider } from './utils/stores/bookingSections/StepperStore';
import { BookingStoreProvider } from './utils/stores/BookingStore';
import { ProductsStoreProvider } from './utils/stores/ProductsStore';

ReactDOM.render(
    <ProductsStoreProvider>
        <StepperStoreProvider>
            <BookingStoreProvider>
                <App />
            </BookingStoreProvider>
        </StepperStoreProvider>
    </ProductsStoreProvider>,
    document.getElementById('root')
);
