import { floor, lowerCase } from 'lodash';
import moment from 'moment';
import { Booking, Participant, ParticipantInfoField, ParticipantInfoFieldType } from '../models/bookings';
import { ApplyExtras, Extras, Product } from '../models/products';
import { RezdyBooking, RezdyExtra, RezdyImage, RezdyProduct, RezdyQuantity } from '../models/rezdyObjects';
import { convertToSearchableName } from './common';

const convertRezdyImage = (productName: string, image: RezdyImage, i: number) => {
    const newImage = {
        thumbnailURL: image.thumbnailUrl ? image.thumbnailUrl : undefined,
        mediumURL: image.mediumSizeUrl,
        largeURL: image.largeSizeUrl,
        url: image.itemUrl,
        alt: `${productName} - Whitsunday Day Trip Airlie Beach ${i + 1}`
    };
    return newImage;
};

const convertListOptions = (listOptions?: string): string[] => (listOptions ? listOptions?.split('\r\n') : []);

const convertExtraToParseportsType = (rezdyExtra: RezdyExtra): Extras => ({
    id: rezdyExtra.name,
    name: rezdyExtra?.name || '',
    description: rezdyExtra?.description || '',
    image: rezdyExtra?.image && {
        url: rezdyExtra.image.itemUrl,
        thumbnailURL: rezdyExtra.image.thumbnailUrl,
        alt: rezdyExtra.name
    },
    price: floor(100 * (rezdyExtra?.price || 0)),
    appliesTo: rezdyExtra?.extraPriceType !== 'QUANTITY' ? ApplyExtras.PER_BOOKING : ApplyExtras.PER_PERSON
});

export const convertRezdyProduct = (originalProduct: Product, rezdyProduct: RezdyProduct) => {
    const participantInfoFields = rezdyProduct?.bookingFields
        .filter((bookingField: any) => bookingField && bookingField.requiredPerParticipant)
        .reduce((acc: ParticipantInfoField[], bookingField: any) => {
            const convertedType = lowerCase(bookingField.fieldType);
            const participantInfoField: any = {
                label: bookingField.label,
                value: bookingField.value,
                fieldType: convertedType === 'string' ? ParticipantInfoFieldType.TEXT : convertedType
            };
            if (convertedType === ParticipantInfoFieldType.LIST) {
                participantInfoField.listOptions = convertListOptions(bookingField.listOptions);
            }
            return acc.concat(participantInfoField);
        }, []) as any;
    const tempObject: Product = rezdyProduct && {
        ...originalProduct,
        productCode: rezdyProduct.productCode,
        name: rezdyProduct.name,
        cancellationPeriod: rezdyProduct.cancellationPolicyDays
            ? rezdyProduct.cancellationPolicyDays
            : originalProduct.cancellationPeriod,
        maxQuantity: rezdyProduct.quantityRequiredMax,
        searchableName: convertToSearchableName(rezdyProduct.name),
        shortDescription: rezdyProduct.shortDescription,
        longDescription: rezdyProduct.description,
        duration: rezdyProduct.durationMinutes,
        participantInfoFields: participantInfoFields,
        photos:
            rezdyProduct.images && rezdyProduct.images.map((img, i) => convertRezdyImage(rezdyProduct.name, img, i)),
        priceOptions: rezdyProduct.priceOptions.map((option) => {
            const opt = {
                label: option.label,
                price: option.price,
                seatsUsed: option.seatsUsed
            };
            return opt;
        }),
        displayPrice: {
            label: undefined,
            price: rezdyProduct.advertisedPrice
        },
        extras: (rezdyProduct.extras || []).map(convertExtraToParseportsType),
        minimumNoticeMinutes: rezdyProduct.minimumNoticeMinutes,
        pickupID: rezdyProduct.pickupId
    };
    return tempObject;
};

const participantsToRezdyParticipants = (participant: Participant, product: Product) => {
    const filterFields = participant.fields.filter((field) =>
        product.participantInfoFields.find((f) => f.label === field.label)
    );
    return {
        fields: (filterFields || []).map((field) => ({
            label: field.label,
            value: field.value
        }))
    };
};

const extraToRezdyExtra = (extras: Extras[]) => {
    const selectedExtras = extras.filter((ex) => ex.quantity > 0);
    return selectedExtras.map((ext) => ({
        name: ext.name,
        price: floor((ext.price || 0) / 100, 2),
        quantity: ext.quantity
    }));
};

export const convertToRezdyBooking = (booking: Booking, product: Product) => {
    const updatedParticipants = booking.participants.map((part) => participantsToRezdyParticipants(part, product));
    const rezBooking: RezdyBooking = {
        comments: booking.comment,
        customer: booking.customer,
        items: [
            {
                startTime: moment(booking.tour.startTimeLocal).toISOString(),
                extras: extraToRezdyExtra(booking.extras),
                participants: updatedParticipants,
                pickupLocation: {
                    locationName: booking.tour.pickUpLocation
                },
                productCode: booking.tour.productCode,
                quantities: booking.quantities.map((quan) => {
                    const quantityObject: RezdyQuantity = {
                        optionLabel: quan.label,
                        optionPrice: quan.price,
                        value: quan.value
                    };
                    return quantityObject;
                })
            }
        ],
        payments: [
            {
                amount: booking.payment.amount,
                type: booking.payment.type,
                date: booking.payment.date
            }
        ],
        sendNotifications: false,
        source: 'API',
        status: 'CONFIRMED',
        internalNotes: booking.comment,
        totalAmount: booking.totalAmount
    };
    return rezBooking;
};

export const createRezdyPackageBooking = (
    booking: Booking,
    packageBooking: Booking,
    product: Product,
    packageProduct: Product
) => {
    const updatedParticipants = booking.participants.map((part) =>
        participantsToRezdyParticipants(part, packageProduct)
    );
    const rezBooking: RezdyBooking = {
        comments: `Booking created by ${product.entity}. Package booking, payment taken with lead booking ref ${booking.bookingResponse.refNum}`,
        customer: packageBooking.customer,
        items: [
            {
                startTime: moment(packageBooking.tour.startTimeLocal).toISOString(),
                participants: updatedParticipants,
                pickupLocation: {
                    locationName: packageBooking.tour.pickUpLocation
                },
                productCode: packageProduct.productCode,
                quantities: packageBooking.quantities.map((quan) => {
                    const quantityObject: RezdyQuantity = {
                        optionLabel: quan.label,
                        optionPrice: quan.price,
                        value: quan.value
                    };
                    return quantityObject;
                })
            }
        ],
        payments: [
            {
                amount: 0,
                type: 'INVOICE',
                date: booking.payment.date
            }
        ],
        sendNotifications: false,
        source: 'API',
        status: 'CONFIRMED',
        internalNotes: `Booking created by ${product.entity}. Package booking, payment taken with lead booking ref ${booking.bookingResponse.refNum}`,
        totalAmount: 0
    };
    return rezBooking;
};
