import {
    Box,
    Collapse,
    Container,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import WideLogo from '../../assets/squareLogo.png';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import SubjectIcon from '@material-ui/icons/Subject';
import { Skeleton } from '@material-ui/lab';
import FlightIcon from '@material-ui/icons/Flight';

const tourLinks = [
    {
        title: 'Airlie Beach',
        link: '/airlie'
    },
    {
        title: 'Cairns',
        link: '/cairns'
    }
];

const FullWidth = () => {
    const productController = useContext(ProductsStore);
    const theme = useTheme();

    return (
        <Container maxWidth="xl">
            <header className="site-header mo-left header header-2" style={{ marginTop: 10 }}>
                <nav className="sticky-header navbar-expand-lg main-bar-wraper">
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '0 1rem'
                            }}
                        >
                            <div>
                                <Link to={''}>
                                    <img
                                        src={WideLogo}
                                        alt="GSL Aviation Logo"
                                        style={{
                                            height: 75,
                                            cursor: 'pointer',
                                            color: theme.palette.primary.main
                                        }}
                                    />
                                </Link>
                            </div>
                            <div
                                className="header-nav navbar-collapse collapse navbar myNavbar float-right"
                                id="navbarNavDropdown"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                <ul className="nav navbar-nav">
                                    <li>
                                        <Link to={''}>Home</Link>
                                    </li>
                                    <li className="active"></li>
                                    {productController.isLoading ? (
                                        <Box
                                            style={{
                                                padding: '23px 15px'
                                            }}
                                        >
                                            <Skeleton width={60} height={30} />
                                        </Box>
                                    ) : (
                                        <li>
                                            <Link to={'#'}>
                                                Tours <i className="fa fa-chevron-down"></i>
                                            </Link>
                                            <ul className="sub-menu">
                                                {tourLinks.map((sect) => (
                                                    <li key={sect.title}>
                                                        <Link to={sect.link} className="dez-page">
                                                            {sect.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    )}
                                    <li>
                                        <Link to={'#'}>
                                            About <i className="fa fa-chevron-down"></i>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to={'/termsandconditions'} className="dez-page">
                                                    Terms &amp; Conditions
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/fleet'} className="dez-page">
                                                    Our Fleet
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to={'contactus'} className="dez-page">
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </Container>
    );
};

const headerStyles = makeStyles((theme) => ({
    drawer: {
        '& .MuiDrawer-paper': {
            minWidth: 400,
            [theme.breakpoints.only('xs')]: {
                width: '100%',
                minWidth: 'unset'
            }
        }
    }
}));

const SmallHeader = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tourIconOpen, setTourIconOpen] = useState(false);
    const [aboutIconOpen, setAboutIconOpen] = useState(false);
    const productController = useContext(ProductsStore);
    const classes = headerStyles();
    const theme = useTheme();

    const handleTourList = () => {
        setTourIconOpen(!tourIconOpen);
    };

    const handleAboutList = () => {
        setAboutIconOpen(!aboutIconOpen);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <Container maxWidth="xl" style={{ padding: 15 }}>
            <Grid container justifyContent="space-between" alignItems="center" style={{ paddingLeft: 8 }}>
                <Grid item xs="auto">
                    <Link to={''}>
                        <img
                            src={WideLogo}
                            alt="GSL Aviation Logo"
                            style={{ height: 70, cursor: 'pointer', color: theme.palette.primary.main }}
                        />
                    </Link>
                </Grid>
                <Grid item xs={true} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Grid item>
                        <IconButton onClick={toggleDrawer(true)} aria-label="Menu Icon">
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} className={classes.drawer}>
                    <Box
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            paddingTop: 7
                        }}
                    >
                        <ListItem
                            button
                            onClick={toggleDrawer(false)}
                            style={{ padding: '0 20px 15px 15px', justifyContent: 'space-between' }}
                        >
                            <ListItemIcon>
                                <img
                                    src={WideLogo}
                                    alt="GSL Aviation logo"
                                    style={{ height: 60, paddingTop: 5, cursor: 'pointer' }}
                                />
                            </ListItemIcon>
                            <CloseIcon style={{ opacity: 0.8 }} />
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to={'/'}
                            onClick={toggleDrawer(false)}
                            style={{ padding: '7px 20px' }}
                        >
                            <ListItemIcon>
                                <HomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button onClick={handleTourList} style={{ padding: '7px 20px' }}>
                            <ListItemIcon>
                                <DirectionsBoatIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Tours" />
                            {tourIconOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={tourIconOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {tourLinks.map((sect) => (
                                    <ListItem
                                        key={sect.title}
                                        button
                                        component={Link}
                                        to={sect.link}
                                        onClick={toggleDrawer(false)}
                                        style={{
                                            paddingLeft: 35,
                                            paddingRight: 25,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            opacity: 0.9
                                        }}
                                    >
                                        <ListItemText>
                                            <Grid container>
                                                <Grid item xs="auto">
                                                    •
                                                </Grid>
                                                <Grid item xs={true} style={{ paddingLeft: 10 }}>
                                                    {sect.title}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                        <ListItem button onClick={handleAboutList} style={{ padding: '7px 20px' }}>
                            <ListItemIcon>
                                <InfoIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="About Us" />
                            {aboutIconOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={aboutIconOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem
                                    button
                                    component={Link}
                                    to={'/fleet'}
                                    onClick={toggleDrawer(false)}
                                    style={{
                                        paddingLeft: 35,
                                        paddingRight: 25,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        opacity: 0.9
                                    }}
                                >
                                    <ListItemIcon>
                                        <FlightIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Our Fleet" />
                                </ListItem>
                                <ListItem
                                    button
                                    component={Link}
                                    to={'/termsandconditions'}
                                    onClick={toggleDrawer(false)}
                                    style={{
                                        paddingLeft: 35,
                                        paddingRight: 25,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        opacity: 0.9
                                    }}
                                >
                                    <ListItemIcon>
                                        <SubjectIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Terms &amp; Conditions" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem
                            button
                            component={Link}
                            to={'/contactus'}
                            onClick={toggleDrawer(false)}
                            style={{ padding: '7px 20px' }}
                        >
                            <ListItemIcon>
                                <PhonelinkRingIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Contact Us" />
                        </ListItem>
                    </Box>
                </Drawer>
            </Grid>
        </Container>
    );
};

export default function Header() {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {!smallScreen && <FullWidth />}
            {smallScreen && <SmallHeader />}
        </>
    );
}
