import { useEffect } from 'react';
import { State } from '../../functions/hooks';
import IsMountedWrapper from '../../functions/isMountedWrapper';
import { Booking } from '../../models/bookings';
import { Extras, Product } from '../../models/products';
import { WrappedSetter } from '../BaseStore';

export interface ExtraSelectionStepControllerValue {
    readonly extrasNotSelected: Extras[];
    readonly handleSelectedExtraChange: (currentExtra: Extras, quantity: number) => void;
    readonly addExtraToBooking: (currentExtra: Extras) => void;
}

export default function ExtraSelectionStepController(
    setBooking: WrappedSetter<Booking>,
    booking: Booking,
    product: Product
): ExtraSelectionStepControllerValue {
    const isMounted = IsMountedWrapper();
    const extrasNotSelected = State<Extras[]>([], isMounted);

    const addExtraToBooking = (currentExtra: Extras) => {
        extrasNotSelected.data.map((e) => e?.id && e.id).includes(currentExtra?.id) &&
            setBooking(
                (prev) => ({
                    ...prev,
                    extras: (prev.extras || []).concat({
                        ...extrasNotSelected.data.find((extra) => extra.id === currentExtra.id),
                        quantity: 0
                    })
                }),
                isMounted
            );
    };

    const handleSelectedExtraChange = (currentExtra: Extras, quantity: number) => {
        currentExtra?.id &&
            setBooking(
                (prev) => ({
                    ...prev,
                    extras: prev.extras.map((ex) =>
                        ex?.id === currentExtra.id ? { ...currentExtra, quantity: quantity } : ex
                    )
                }),
                isMounted
            );
    };

    useEffect(() => {
        const selectedExtraIds = (booking?.extras || []).map((e) => e.id!);
        const notSelected = (product?.extras || []).map((e) => !selectedExtraIds.includes(e.id) && e);
        extrasNotSelected.set(notSelected);
    }, [booking.extras, product]);

    return {
        extrasNotSelected: extrasNotSelected.data,
        handleSelectedExtraChange,
        addExtraToBooking
    };
}
