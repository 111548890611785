import { Box, Container, Grid, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core/';
import { map } from 'lodash';
import { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';

const useStyles = makeStyles((theme) => ({
    containerPadding: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    listContainer: {
        margin: '20px auto',
        maxWidth: 900
    },
    list: {
        marginBottom: 40,
        marginTop: 30
    },
    title: {
        margin: 15
    }
}));

export default function TermsAndConditions() {
    const classes = useStyles();
    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.remove();
        }
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const termsList = [
        'Prices listed on this site are per person, and include GST',
        'GSL Aviation does not operate aviation tour products in the Whitsundays. Products sold on this site are booked with partner providers, and subject to their terms and conditions.',
        'Package products booked with GSL Aviation, are also subject to the terms and conditions of the partner providers.',
        'Some tours are not suitable for cruise ship guests, or guests of island resorts.',
        'Cruise ship passengers are responsible for checking tour times prior to booking, and ensuring that tender times will suit; GSL will not honor refunds or cancellations within 24 hours, if guests cannot travel because of tender time restrictions.',
        'GSL will not honor refunds or cancellations within 24 hours, for guests that are staying at Island Resorts, and cannot make their tour because of ferry time restrictions.',
        'Weight restrictions apply on all flight products.',
        'Minimum numbers apply for tours to proceed.',
        'Tours and pricing are subject to change without notice.',
        'Bus tranfers are subject to availability, and GSL will advise you if this service is not available',
        'Passengers that do not arrive on time for check-on, including scheduled bus pick-ups, may be classed as a no-show. Please allow extra time for parking (if applicable), and plan to arrive 10 minutes before your scheduled check-in time.',
        'A 100% cancellation fee applies for cancellations, no shows, or booking changes within 24 hours of departure.',
        'Tours, or portions of tours, may be cancelled by adverse weather conditions or engineering reasons. GSL will endeavour to reschedule tours, accommodate guests on other tours, or provide alternative options. In the event that guests are not able to travel at all, the fare will be refunded.',
        'Should any one part of a package tour not operate - you will be charged the current retail price of the tour component(s) completed',
        'All flights are subject to pilot discretion, weather, air traffic control and aircraft availability',
        'Flight time listed is approximate and may vary depending on aircraft type',
        'Travel agents shall ensure terms and conditions are communicated with clients',
        'All tours are covered by carriers liability insurance, specific to the prescribed activitiies being undertaken; those policies may not extend to claims where guests are found to be negligent, such as not following directions of crew.',
        'GSL highly recommend that all guest have their own travel insurance, to cover losses such as lost/damaged personal items on tour, injuries caused by negligence, tour delays, illness, and other such scenarios which may cause financial or personal loss outside of prescribed liabilites under company policies. GSL Aviation will not provide compensation for losses that are not covered under our relevant policies, such as lost income, or costs incurred by changing travel plans.',
        'GSL Aviation will endeavour to achieve on-time performance, but accept no responsibility for missing tour/flight connections as a result of operational delays',
        'GSL Aviation may use photographs of our clients in promotional material, including but not limited to social media, if you do not consent please advise GSL Aviation',
        'American Express and Diners Club cards are not accepted'
    ];

    return (
        <div>
            <Helmet>
                <title>
                    Terms &amp; Conditions | GSL Aviation | Airlie Beach &amp; Cairns Scenic Flights, Tours &amp; Air
                    Charter
                </title>
                <meta
                    name="description"
                    content="Contact GSL Aviation at our office in Cairns or Airlie Beach. GSL Aviation can assist with air charter, scenic flights, and tours."
                />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle title="Terms &amp; Conditions" tagline="A few things to know for your trip with GSL" />
            </Container>
            <Container maxWidth="md" className={classes.containerPadding}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.list}>
                            <List>
                                {map(termsList, (term, i) => (
                                    <ListItem key={`term${i + 1}`} style={{ padding: 5, opacity: 0.8 }}>
                                        <ListItemText>
                                            <Box style={{ display: 'flex' }}>
                                                <Typography style={{ minWidth: 25, textAlign: 'right' }}>
                                                    {i + 1}.
                                                </Typography>
                                                <Typography style={{ marginLeft: 6 }}>{term}</Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
