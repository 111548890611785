import React, { Suspense } from 'react';
import { useImage } from 'react-image';
import { Skeleton } from '@material-ui/lab';
import { FleetImage } from '../../pages/FleetPage';
import { Slide, SlideProps } from '@material-ui/core';

interface FleetImageDisplayProps {
    readonly image: FleetImage;
    readonly height?: number;
    readonly slide?: boolean;
    readonly slideDirection?: string;
}

const Image = ({ image, height }: FleetImageDisplayProps) => {
    const source = useImage({
        srcList: [image.src, image.fallback]
    });

    return (
        <img
            src={source.src}
            alt={image.alt}
            height={height}
            style={{
                objectFit: 'cover'
            }}
        />
    );
};

export default function FleetImageDisplay({ image, height, slide, slideDirection }: FleetImageDisplayProps) {
    return (
        <Slide in={slide} direction={slideDirection as SlideProps['direction']}>
            <div style={{ width: '100%', height: '100%' }}>
                <Suspense fallback={<Skeleton variant="rect" height={height} width="100%" />}>
                    <Image image={image} />
                </Suspense>
            </div>
        </Slide>
    );
}
