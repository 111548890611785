import { makeStyles } from '@material-ui/core/styles';

export const widgetStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
        borderRadius: 10,
        height: '100%',
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            paddingTop: 15,
            border: 'none'
        }
    },
    paperLeft: {
        paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
            marginRight: 10
        },
        [theme.breakpoints.only('xs')]: {
            marginRight: 0
        }
    },
    paperRight: {
        paddingBottom: 9,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10
        },
        [theme.breakpoints.only('xs')]: {
            marginLeft: 0
        }
    },
    gridContainer: {
        paddingBottom: 20
    },
    gridItem: {
        width: '100%'
    },
    detailText: {
        fontWeight: 400,
        color: theme.palette.text.soft,
        fontSize: '1.1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
        }
    }
}));
