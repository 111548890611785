import { makeStyles } from '@material-ui/core';
import { gradients } from '../../utils/theme';

export const productGridStyles = makeStyles((theme) => ({
    background: {
        paddingTop: 20,
        paddingBottom: 20
    },
    backgroundColor: {
        backgroundColor: theme.palette.background.subtle
    },
    gridContainer: {
        flexDirection: 'unset',
        height: '100%',
        [theme.breakpoints.between('sm', 'md')]: {
            flexDirection: 'row'
        }
    },
    popGrid: {
        padding: '10px 0',
        justifyContent: 'flex-start',
        [theme.breakpoints.only('md')]: {
            width: '90%',
            margin: 'auto'
        },
        [theme.breakpoints.only('sm')]: {
            maxWidth: 550,
            margin: 'auto'
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    imageCommon: {
        position: 'relative',
        overflow: 'hidden'
    },
    imageTop: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
    imageLeft: {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 0,
        height: '100%'
    },
    featureImage: {
        position: 'relative',
        objectFit: 'cover',
        height: 260,
        width: '100%',
        '&:hover': {
            opacity: '0.8'
        },
        [theme.breakpoints.only('md')]: {
            height: '100%'
        },
        [theme.breakpoints.only('sm')]: {
            height: 310
        }
    },
    featureDescription: {
        position: 'relative',
        padding: '15px 15px 35px',
        textAlign: 'left',
        lineHeight: '1.1rem',
        [theme.breakpoints.up('lg')]: {
            minHeight: 260
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: 200
        },
        [theme.breakpoints.between('sm', 'md')]: {
            paddingBottom: 15
        }
    },
    title: {
        fontSize: 28,
        color: 'rgb(0 0 0 / 80%)',
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        paddingBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        }
    },
    body: {
        fontSize: 15,
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 10
    },
    priceOffer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 20,
        marginLeft: 20,
        paddingBottom: 20,
        '& h5': {
            margin: 0,
            fontWeight: 500,
            color: 'rgb(0 0 0 / 80%)'
        },
        [theme.breakpoints.only('xs')]: {
            marginLeft: 15,
            marginRight: 15,
            paddingBottom: 15
        }
    },
    featureButton: {
        width: 140,
        height: 42,
        background: gradients.circular,
        [theme.breakpoints.down('sm')]: {
            width: 120
        }
    },
    onSale: {
        margin: '10px 10px 0 0',
        minWidth: 125,
        padding: '1px 14px 1px 8px',
        height: 40,
        background: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 15,
        fontWeight: 500,
        [theme.breakpoints.only('xs')]: {
            padding: '1px 12px 1px 6px',
            fontSize: 14,
            minWidth: 110,
            height: 37,
            margin: '10px 5px 0 0'
        }
    }
}));
