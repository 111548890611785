import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isEmpty, startCase, unionBy } from 'lodash';
import React, { ReactNode, useContext, useEffect } from 'react';
import { State } from '../../../utils/functions/hooks';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { ParticipantInfoField, ParticipantInfoFieldType } from '../../../utils/models/bookings';
import { PriceOption } from '../../../utils/models/products';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import { commonStyles } from './commonStyles';

export const toMenuItem = (
    native: boolean,
    value: string | number,
    key: string | number,
    children: ReactNode,
    disabled?: boolean
) =>
    native ? (
        <option key={key} value={value} disabled={disabled}>
            {children}
        </option>
    ) : (
        <MenuItem key={key} value={value} disabled={disabled}>
            {children}
        </MenuItem>
    );

interface ParticipantInfoFieldViewProps {
    readonly field: ParticipantInfoField;
    readonly value?: string;
    readonly participantIndex: number;
    readonly onChange: (value: string) => void;
    readonly className?: any;
}

declare global {
    interface Window {
        MSStream: any;
    }
}

function ParticipantInfoFieldView(props: ParticipantInfoFieldViewProps) {
    const controller = useContext(BookingStore);
    const isMounted = IsMountedWrapper();
    const participantErrors = controller.participantErrors[props.participantIndex] || {};
    const error = participantErrors[props.field.label || ''] || '';
    const isMobileOS = State(false, isMounted);
    const MSStream = window.MSStream;
    const selectValue = props.value || '';

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || navigator.platform;
        isMobileOS.set(
            /windows phone/i.test(userAgent) ||
                /android/i.test(userAgent) ||
                (/iPad|iPhone|iPod/.test(userAgent) && !MSStream)
        );
    }, []);

    switch (props.field.fieldType) {
        case ParticipantInfoFieldType.TEXT:
            return (
                <TextField
                    label={props.field.label}
                    error={!isEmpty(error)}
                    helperText={error}
                    className={props.className || null}
                    variant="outlined"
                    defaultValue={props.value || ''}
                    onBlur={(event) => props.onChange(event.target.value.trim())}
                    onChange={(event) => props.onChange(event.target.value)}
                />
            );
        case ParticipantInfoFieldType.BOOLEAN:
            return (
                <FormControl className={props.className || null} variant="outlined" error={!isEmpty(error)}>
                    <Select
                        label={props.field.label}
                        value={props.value || ''}
                        onChange={(event) => props.onChange(event.target.value as string)}
                    >
                        {['true', 'false'].map((item) => toMenuItem(isMobileOS.data, item, item, startCase(item)))}
                    </Select>
                    <FormHelperText>{error}</FormHelperText>
                </FormControl>
            );
        case ParticipantInfoFieldType.NUMBER:
            return (
                <TextField
                    label={props.field.label}
                    className={props.className || null}
                    variant="outlined"
                    error={!isEmpty(error)}
                    helperText={error}
                    defaultValue={props.value || ''}
                    type="number"
                    onBlur={(event) => props.onChange(event.target.value.trim())}
                    onChange={(event) => props.onChange(event.target.value)}
                />
            );
        case ParticipantInfoFieldType.LIST:
            return (
                <TextField
                    select
                    className={props.className || null}
                    error={!isEmpty(error)}
                    variant="outlined"
                    helperText={error}
                    label={
                        props.field.label.length > 28 ? props.field.label.substring(0, 30) + '...' : props.field.label
                    }
                    placeholder={'Select From List'}
                    value={selectValue}
                    onChange={(event) => props.onChange(event.target.value as string)}
                    SelectProps={{
                        native: isMobileOS.data
                    }}
                >
                    {toMenuItem(isMobileOS.data, '', 'default', '')}
                    {(props.field.listOptions || []).map((item) =>
                        toMenuItem(isMobileOS.data, item, item, startCase(item))
                    )}
                </TextField>
            );
        default:
            return (
                <TextField
                    className={props.className || null}
                    label={props.field.label}
                    error={!isEmpty(error)}
                    helperText={error}
                    variant="outlined"
                    defaultValue={props.value || ''}
                    type="email"
                    onBlur={(event) => props.onChange(event.target.value.trim())}
                    onChange={(event) => props.onChange(event.target.value)}
                />
            );
    }
}

interface ParticipantViewProps {
    readonly fare: PriceOption;
    readonly participantIndex: number;
}

export const PaperContainer = styled(Paper)(() => ({
    padding: '20.15px 16px'
}));

function ParticipantView(props: ParticipantViewProps) {
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const classes = commonStyles();
    const minMediumWidth = useMediaQuery('(min-width: 800px');
    const infoFields = productController.product?.allowPackage
        ? unionBy(
              productController.product?.participantInfoFields,
              productController.packageProduct.data?.participantInfoFields,
              'label'
          ).filter((field) => field.label !== 'Number of Passengers')
        : productController.product?.participantInfoFields;

    const getByLabel = (label: string) =>
        (bookingController.booking.participants || [])[props.participantIndex]?.fields?.find(
            (field) => field.label === label
        ) || {};

    return (
        <Box style={{ paddingTop: 10, paddingBottom: 20 }}>
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography>
                        Participant/Option {props.participantIndex + 1} - {props.fare.label}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-end" className={classes.fieldInputSection}>
                        {((infoFields as any) || []).map((participantInfoField, index) => (
                            <Grid
                                item
                                style={{ paddingBottom: 8 }}
                                key={index}
                                xs={(infoFields?.length || 0) > 1 ? (minMediumWidth ? 6 : 12) : 12}
                            >
                                <ParticipantInfoFieldView
                                    className={classes.fieldInputItem}
                                    field={participantInfoField}
                                    participantIndex={props.participantIndex}
                                    onChange={(value) =>
                                        bookingController.handleParticipantInfoFieldChange(props.participantIndex, {
                                            ...participantInfoField,
                                            value
                                        })
                                    }
                                    value={getByLabel(participantInfoField.label || '').value}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default function ParticipantsInfoStep() {
    const classes = commonStyles();
    const bookingController = useContext(BookingStore);
    let participantId = 0;
    const participantFields = (bookingController.booking.quantities || []).reduce((acc, fare) => {
        const quantity = (fare.seatsUsed || 1) * (fare.value || 0);
        const items = [...Array(quantity)].map((_, index) => (
            <Grid xs={12} item key={`fare.id ${fare.label} ${index + 1}`}>
                <ParticipantView participantIndex={participantId++} fare={fare} />
            </Grid>
        ));

        return acc.concat(items);
    }, [] as JSX.Element[]);

    return (
        <Grid container className={clsx(classes.container, classes.overflow)}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                    Booking Details
                </Typography>
                <Typography className={classes.subtitle}>
                    Nice one, now we just need some details for each participant, or booking item
                </Typography>
            </Grid>
            {participantFields}
        </Grid>
    );
}
