import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { Box, Grid, Typography } from '@material-ui/core';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { commonStyles } from './commonStyles';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import PackageDateTimeSelect from './PackageDateTimeSelect';
import InfoIcon from '@material-ui/icons/Info';

export default function CustomerDetails() {
    const classes = commonStyles();
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);

    return (
        <Box className={classes.container}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>
                        Tell us a bit about yourself
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.subtitle}>
                        Your details will be used as the primary contact for the booking
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={classes.fieldInputSection}>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        error={bookingController.customerErrors.firstName !== undefined}
                        helperText={bookingController.customerErrors.firstName}
                        className={classes.fieldInputItem}
                        color="primary"
                        required
                        name="firstName"
                        value={bookingController.booking.customer.firstName}
                        onChange={(event) => bookingController.handleCustomerChange(event)}
                        onBlur={(event) => bookingController.handleCustomerChange(event)}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.fieldInputSection}>
                    <TextField
                        label="Surname"
                        variant="outlined"
                        error={bookingController.customerErrors.lastName !== undefined}
                        helperText={bookingController.customerErrors.lastName}
                        className={classes.fieldInputItem}
                        color="primary"
                        required
                        name="lastName"
                        value={bookingController.booking.customer.lastName}
                        onChange={(event) => bookingController.handleCustomerChange(event)}
                        onBlur={(event) => bookingController.handleCustomerChange(event)}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.fieldInputSection}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        error={bookingController.customerErrors.email !== undefined}
                        helperText={bookingController.customerErrors.email}
                        className={classes.fieldInputItem}
                        color="primary"
                        required
                        name="email"
                        value={bookingController.booking.customer.email}
                        onChange={(event) => bookingController.handleCustomerChange(event)}
                        onBlur={(event) => bookingController.handleCustomerChange(event)}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.fieldInputSection}>
                    <TextField
                        label="Phone"
                        variant="outlined"
                        error={bookingController.customerErrors.phone !== undefined}
                        helperText={bookingController.customerErrors.phone}
                        className={classes.fieldInputItem}
                        color="primary"
                        required
                        name="phone"
                        value={bookingController.booking.customer.phone}
                        onChange={(event) => bookingController.handleCustomerChange(event)}
                        onBlur={(event) => bookingController.handleCustomerChange(event)}
                    />
                </Grid>
                {bookingController.booking.isPackage && (
                    <Grid container className={classes.fieldInputSection}>
                        <Box style={{ display: 'flex' }}>
                            <InfoIcon
                                color="primary"
                                fontSize="large"
                                style={{ marginRight: 15, marginTop: 15, fontSize: '2rem' }}
                            />
                            <Typography
                                variant="h6"
                                style={{ margin: '15px 0 10px', opacity: 0.9, width: '100%' }}
                                color="primary"
                            >
                                Let&apos;s organise the rest of your package.
                            </Typography>
                        </Box>
                        <Typography className={classes.subtitle} style={{ width: '100%' }}>
                            Select a departure date and time for the {productController.product.packageProductNames[1]}
                        </Typography>
                        <PackageDateTimeSelect />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
