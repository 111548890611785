import { GSLEntities } from '../models/bookings';
import { emptyProduct, Product, ProductCategories } from '../models/products';

export const baseProducts: Product[] = [
    {
        ...emptyProduct,
        entity: GSLEntities.GSL_WHITSUNDAYS,
        category: ProductCategories.AIRLIE,
        productCode: 'PWGNPK',
        tagline: 'Airlie Beach Scenic Flight',
        cancellationPeriod: 1,
        titleSEO: 'Scenic flight from Airlie Beach, of the Whitsundays & Great Barrier Reef',
        usp: [
            'Amazing views of the Reef & Whitsunday Islands',
            'See Heart Reef, Hill Inlet and Whitehaven from above',
            'Guaranteed Window Seats',
            'Regular Departure Times',
            'See all of the Whitsundays'
        ],
        tags: [...emptyProduct.tags, 'airlie', 'featureTile', 'popular'],
        saleTips: ['Popular']
    },
    {
        ...emptyProduct,
        productCode: 'P7A1YC',
        entity: GSLEntities.MARINE,
        category: ProductCategories.AIRLIE,
        tagline: 'See the Whitsundays from Above & Below',
        titleSEO:
            'Fly and Cruise Heart Reef, Whitehaven Beach, Hill Inlet, and the Whitsunday Islands. Scenic flight and day tour all-in-one!',
        usp: [
            'Fly & Cruise the Whitsundays',
            'Guaranteed Window Seats',
            'See Heart Reef & Hill Inlet',
            'Full day cruise to Whitehaven',
            'Suitable for all ages'
        ],
        tags: [...emptyProduct.tags, 'airlie', 'package', 'featureTile', 'popular'],
        allowPackage: true,
        packageCode: 'PWGNPK',
        packageEntity: GSLEntities.GSL_WHITSUNDAYS,
        packageFare: {
            label: 'Adult',
            seatsUsed: 1
        },
        packageProductNames: ['Whitehaven Beach Club (Cruise)', 'Reef & Island Scenic Flight'],
        sales: [
            {
                startDate: '2022-03-01T00:00:00+1000',
                endDate: '2022-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 299
                    },
                    {
                        label: 'Child (3 to 14 years old)',
                        price: 269
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.AVIATION,
        category: ProductCategories.CAIRNS,
        productCode: 'P93AEL',
        tagline: 'See Green Island and the Great Barrier Reef',
        titleSEO: 'Scenic flight of the Great Barrier Reef and Green Island | Cairns',
        usp: [
            'Amazing views of Green Island',
            'See the Great Barrier Reef from above',
            'Guaranteed Window Seats',
            'Courtesy Bus from Cairns City',
            'Great Photo Opportunities'
        ],
        tags: [...emptyProduct.tags, 'cairns', 'popular', 'featureTile'],
        saleTips: ['Popular'],
        sales: [
            {
                startDate: '2023-02-20T00:00:00+1000',
                endDate: '2023-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 199
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.AVIATION,
        category: ProductCategories.CAIRNS,
        productCode: 'PJWSQY',
        tagline: 'See the Great Barrier Reef and Tropical North',
        titleSEO: 'Scenic flight of the Great Barrier Reef and and Port Douglas from Cairns',
        usp: [
            'Views of Cairns and Green Island',
            'See the Great Barrier Reef & Port Douglas from above',
            'Guaranteed Window Seats',
            'Courtesy Bus from Cairns City',
            'Great Photo Opportunities of the Reef & Coastline'
        ],
        tags: [...emptyProduct.tags, 'cairns'],
        sales: [
            {
                startDate: '2023-02-20T00:00:00+1000',
                endDate: '2023-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 259
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.AVIATION,
        category: ProductCategories.CAIRNS,
        productCode: 'P3SACG',
        tagline: 'See the Great Barrier Reef and Kuranda Rainforest',
        titleSEO: 'Scenic flight of the Great Barrier Reef, with Skyrail and Kuranda railway',
        usp: [
            '40 minute scenic flight of Cairns and Green Island',
            'Skyrail to Kuranda',
            'Scenic Railway return to Cairns',
            'Courtesy Bus included from Cairns City',
            'Full Day Adventure'
        ],
        tags: [...emptyProduct.tags, 'cairns', 'package', 'popular', 'featureTile'],
        sales: [
            {
                startDate: '2023-02-20T00:00:00+1000',
                endDate: '2023-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 279
                    },
                    {
                        label: 'Child (4 to 14 years old)',
                        price: 239
                    },
                    {
                        label: 'Child (3 years old)',
                        price: 199
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.AVIATION,
        category: ProductCategories.CAIRNS,
        productCode: 'P01FES',
        tagline: 'See the Great Barrier Reef from above & below',
        titleSEO: 'Scenic flight of the Great Barrier Reef, with Green Island cruise from Cairns',
        usp: [
            '40 minute scenic flight of Cairns and Green Island',
            'Cruise to Green Island with Big Cat',
            'Includes lunch, snorkelling, and Green Island',
            'Courtesy Bus included from Cairns City',
            'Full Day Adventure'
        ],
        tags: [...emptyProduct.tags, 'cairns', 'package', 'popular'],
        sales: [
            {
                startDate: '2023-02-20T00:00:00+1000',
                endDate: '2023-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 289
                    },
                    {
                        label: 'Child (4 to 14 years old)',
                        price: 239
                    },
                    {
                        label: 'Child (3 years old)',
                        price: 199
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.AVIATION,
        category: ProductCategories.CAIRNS,
        productCode: 'PSQ9LK',
        tagline: 'Fly over the Reef, and sail with Passions of Paradise',
        titleSEO: 'Scenic flight of the Great Barrier Reef, with Passions of Paradise Cruise from Cairns',
        usp: [
            '40 minute scenic flight of Cairns and Green Island',
            'Cruise to the Great Barrier Reef with Passions of Paradise',
            'Full day sail to the Great Barrier Reef',
            'Includes snorkelling at two locations',
            'Optional diving available on-board'
        ],
        tags: [...emptyProduct.tags, 'cairns', 'package', 'popular'],
        sales: [
            {
                startDate: '2023-02-20T00:00:00+1000',
                endDate: '2023-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 369
                    },
                    {
                        label: 'Child (3 to 14 years old)',
                        price: 309
                    },
                    {
                        label: '2 Adults + 2 Children',
                        price: 1299
                    }
                ]
            }
        ]
    },
    {
        ...emptyProduct,
        entity: GSLEntities.MARINE,
        category: ProductCategories.AIRLIE,
        productCode: 'P14SFD',
        tagline: 'Airlie Beach Tour by Jet Ski',
        titleSEO: 'The best value Jet Ski tour in Airlie Beach',
        usp: [
            '60 Minute Jet Ski Tour',
            'Self drive your own ski',
            'Suitable for beginners',
            'No licence required',
            '2 Persons may share a ski'
        ],
        tags: [...emptyProduct.tags, 'airlie', 'popular']
    },
    {
        ...emptyProduct,
        entity: GSLEntities.MARINE,
        category: ProductCategories.AIRLIE,
        productCode: 'PXPGQT',
        tagline: 'Airlie Beach direct to Whitehaven Beach',
        titleSEO: 'Whitehaven Beach Tour from Airlie Beach',
        usp: [
            'Best value Whitehaven Beach tour',
            'Travels direct to Whitehaven',
            'Build your own experience',
            'Comfortable & Fast vessel',
            'Suitable for all ages'
        ],
        tags: [...emptyProduct.tags, 'airlie', 'popular'],
        saleTips: ['Great Value'],
        sales: [
            {
                startDate: '2023-12-01T00:00:00+1000',
                endDate: '2023-12-20T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 119
                    },
                    {
                        label: 'Child 3 -14',
                        price: 79
                    }
                ]
            }
        ]
    }
];

export const sitemapArray = baseProducts.map((p) => ({
    productCode: p.productCode,
    entity: p.entity,
    category: p.category || null
}));

/*

{
        ...emptyProduct,
        entity: GSLEntities.SEAPLANES,
        category: ProductCategories.AIRLIE,
        productCode: 'P81BF2',
        tagline: 'Package and save, with a Fly & Ski Package',
        titleSEO: 'Travel over two days, with a Scenic Flight in the Whitsundays, and Airlie Beach Jet Ski Tour',
        usp: [
            'Fly over the Whitsundays and Great Barrier Reef',
            'Guaranteed Window Seats',
            '60 Minute Guided Jet Ski Tour in Airlie Beach ',
            'Choose depature times to suit your itinerary',
            'Save $29 compared to the full fares'
        ],
        tags: [...emptyProduct.tags, 'airlie', 'package', 'popular'],
        allowPackage: true,
        packageCode: 'P14SFD',
        packageEntity: GSLEntities.MARINE,
        packageFare: {
            label: 'Jet Ski',
            seatsUsed: 1
        },
        packageProductNames: ['Reef & Island Scenic Flight', 'Jet Ski Safari'],
        sales: [
            {
                startDate: '2022-03-01T00:00:00+1000',
                endDate: '2022-03-31T17:59:59+1000',
                fareTypes: [
                    {
                        label: 'Adult',
                        price: 319
                    }
                ]
            }
        ]
    },

*/
