import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { gradients } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
    pageTitleSection: {
        width: '100%',
        paddingTop: '3rem',
        paddingBottom: '2rem',
        backgroundColor: 'white',
        [theme.breakpoints.only('xs')]: {
            padding: '1rem 0',
            textAlign: 'center'
        }
    },
    pageTitleContainer: {
        width: '100%'
    },
    title: {
        backgroundImage: gradients.main,
        '-webkit-background-clip': 'text',
        color: 'transparent',
        fontSize: 55,
        fontWeight: 500,
        paddingBottom: 5,
        [theme.breakpoints.only('sm')]: {
            fontSize: 48
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 36
        }
    },
    tagline: {
        color: theme.palette.primary.dark,
        fontSize: 24,
        opacity: 0.8,
        fontWeight: 400,
        [theme.breakpoints.only('sm')]: {
            fontSize: 20
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 18
        }
    }
}));

interface TitleProps {
    title: string;
    tagline: string;
}

export default function PageTitle({ title, tagline }: TitleProps) {
    const classes = useStyles();

    return (
        <div className={classes.pageTitleSection}>
            <div className={classes.pageTitleContainer}>
                <Typography variant="h1" className={classes.title}>
                    {title || ''}
                </Typography>
                <Typography variant="h6" className={classes.tagline}>
                    {tagline || ''}
                </Typography>
            </div>
        </div>
    );
}
