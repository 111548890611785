import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { Button } from '@material-ui/core';
import SnackbarManager from '../../../utils/SnackbarManager';

const stepperStyles = makeStyles({
    stepperButtons: {
        marginTop: 20,
        marginBottom: 15,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 10,
        height: 54,
        '& .MuiButton-root': {
            width: 120,
            maxWidth: '23%'
        }
    }
});

export default function StepperButtons({ numberOfSteps }) {
    const bookingController = useContext(BookingStore);
    const classes = stepperStyles();

    const handleNext = () => {
        bookingController.checkFormErrors(bookingController.step)
            ? SnackbarManager.warning('Please check the form fields')
            : bookingController.nextStep();
    };

    return (
        <div className={classes.stepperButtons}>
            <Button
                onClick={bookingController.prevStep}
                variant="outlined"
                color="primary"
                disabled={bookingController.step === 0}
            >
                Prev
            </Button>
            <Button
                onClick={() => handleNext()}
                variant="contained"
                color="primary"
                disabled={bookingController.step === numberOfSteps - 1}
            >
                Next
            </Button>
        </div>
    );
}
