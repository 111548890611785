import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTitle from '../components/coreLayout/PageTitle';
import ProductDisplayGrid from '../components/features/ProductDisplayGrid';
import { ProductCategories } from '../utils/models/products';

export const categoryStyles = makeStyles((theme) => ({
    containerPadding: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    title: {
        fontSize: 38,
        color: theme.palette.primary.main,
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            marginTop: 40
        },
        textAlign: 'center'
    },
    body: {
        fontSize: 18,
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 15,
        maxWidth: '75%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            maxWidth: '90%'
        },
        textAlign: 'center'
    }
}));

export default function AirlieTours() {
    const classes = categoryStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>GSL Aviation | Airlie Beach Scenic Flights, Helictoper Tours, &amp; Fly Cruise Packages</title>
                <meta name="description" content="Airlie Beach Tours | Whitehaven Beach | Great Barrier Reef" />
            </Helmet>
            <Container maxWidth="lg" className={classes.containerPadding}>
                <PageTitle
                    title="Airlie Beach &amp; The Whitsundays"
                    tagline="Explore the Whitsundays with our range of tours"
                />
            </Container>
            <Container
                maxWidth="lg"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%' }}
            >
                <Typography variant="h4" className={classes.title}>
                    Explore the Whitsundays
                </Typography>
                <Typography className={classes.body}>
                    Fly over the Whitsundays & Great Barrier Reef, or Cruise to Whitehaven Beach. Our partners offer a
                    range of amazing tours & experiences.
                </Typography>
            </Container>
            <ProductDisplayGrid category={ProductCategories.AIRLIE} />
            <div style={{ marginBottom: 40, width: '100%' }} />
        </>
    );
}
