import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import { availabilityStyles } from './availabilityStyles';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { sum } from 'lodash';

interface CalendarDateSelProps {
    readonly isPackage?: boolean;
}

export default function CalendarDateSel({ isPackage }: CalendarDateSelProps) {
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const isMounted = IsMountedWrapper();
    const classes = availabilityStyles();

    const handleDateChange = (date: any) => {
        isPackage
            ? productController.setAvailability(
                  (prev) => ({
                      ...prev,
                      packageDepartureDate: moment(date).utcOffset(10).toISOString(true)
                  }),
                  isMounted
              )
            : productController.setAvailability(
                  (prev) => ({
                      ...prev,
                      departureDate: moment(date).utcOffset(10).toISOString(true)
                  }),
                  isMounted
              );
    };

    useEffect(() => {
        productController.getAvailability();
    }, [
        isPackage ? productController.availability.packageDepartureDate : productController.availability.departureDate
    ]);

    useEffect(() => {
        productController.getAvailability();
        handleDateChange(
            isPackage
                ? moment(productController.availability.packageDepartureDate).isAfter(
                      moment().add(productController.packageProduct.data.minimumNoticeMinutes, 'minutes')
                  )
                    ? productController.availability.packageDepartureDate
                    : productController.availability.packageSessions.find(
                          (sesh) =>
                              moment(sesh.startTime).isAfter(
                                  moment().add(productController.packageProduct.data.minimumNoticeMinutes, 'minutes')
                              ) &&
                              sesh.seatsRemaining >
                                  sum(
                                      bookingController.packageBooking.data.quantities.map((q) => q.seatsUsed * q.value)
                                  ) &&
                              sesh
                      ).startTime
                : moment()
        );
    }, []);

    return (
        <Grid item xs={12} className={classes.sectionItem}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    disableToolbar
                    label="Select Date"
                    variant="inline"
                    inputVariant="outlined"
                    format="dd MMMM"
                    openTo="date"
                    error={
                        isPackage &&
                        bookingController.customerErrors.packageDate === 'Both products cannot be on the same day'
                    }
                    helperText={
                        isPackage &&
                        bookingController.customerErrors.packageDate === 'Both products cannot be on the same day' &&
                        bookingController.customerErrors.packageDate
                    }
                    autoOk
                    value={
                        isPackage
                            ? moment(productController.availability.packageDepartureDate).utcOffset(10)
                            : moment(productController.availability.departureDate).utcOffset(10)
                    }
                    onChange={handleDateChange}
                    onAccept={handleDateChange}
                    minDate={moment().toISOString()}
                    className={classes.selectFields}
                    style={{ width: isPackage && '100%' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" style={{ paddingRight: 10 }}>
                                <TodayOutlinedIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    );
}
