import { Dispatch, SetStateAction, useState } from 'react';
import { createStore, createStoreContext, DefaultProviderProps } from '../common';

export interface StepperStoreValue {
    readonly step: number;
    readonly setStep: Dispatch<SetStateAction<number>>;
    readonly reset: () => void;
    readonly nextStep: () => void;
    readonly prevStep: () => void;
}

export const StepperStore = createStoreContext<StepperStoreValue>();

export const StepperStoreProvider = ({ children }: DefaultProviderProps) => {
    const [step, setStep] = useState(0);

    const reset = () => {
        setStep(0);
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(Math.max(step - 1, 0));
    };

    return createStore(StepperStore, children, { step, setStep, nextStep, prevStep, reset });
};
