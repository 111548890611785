import { Container, Grid, Hidden, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import InstagramImageGallery from './InstagramImageGallery';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: 'auto',
        paddingTop: 40,
        paddingBottom: 40,
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },
        justifyContent: 'center'
    },
    imageItem: {
        textAlign: 'center',
        padding: 10
    },
    image: {
        height: 260,
        width: 260,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: 10
    },
    textArea: {
        padding: '20px 40px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.only('md')]: {
            padding: '0 20px',
            justifyContent: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            padding: '20px 0'
        },
        [theme.breakpoints.only('xs')]: {
            padding: 20
        },
        maxWidth: 700
    },
    imageArea: {
        justifyContent: 'flex-end',
        paddingLeft: 10,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    title: {
        fontSize: 28,
        color: theme.palette.primary.dark,
        lineHeight: '115%',
        letterSpacing: '-1px',
        fontWeight: 400,
        paddingBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        }
    },
    body: {
        fontSize: 16,
        lineHeight: '140%',
        opacity: 0.8,
        fontWeight: 400,
        paddingBottom: 20,
        paddingTop: 10
    }
}));

export default function InstagramImages() {
    const classes = useStyles();
    const [mediaList, setMediaList] = useState([]);
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const path = process.env.NODE_ENV === 'production' ? 'https://gslwhitsundays.com.au/' : 'http://localhost:5000/';

    const getMediaList = async () => {
        try {
            const res = await axios(`${path}instagram/images?entity=gslAviation`);
            const createList = isArray(res.data)
                ? res.data.map((url, i) => ({
                      url: url,
                      alt: `GSL Aviation Scenic Flight Airlie Beach and Cairns tour - image no. ${i + 1}`
                  }))
                : [];
            setMediaList(createList);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getMediaList();
    }, []);

    return (
        <Container maxWidth="lg" style={{ padding: xsScreen && 0 }}>
            <Grid container className={classes.gridContainer}>
                <Grid container className={classes.imageArea} item xs={12} md={5} lg={6}>
                    <div style={{ maxWidth: 700, width: '100%', height: '100%' }}>
                        <InstagramImageGallery images={mediaList} />
                    </div>
                </Grid>
                <Grid item xs={12} md={7} lg={6} className={classes.textArea}>
                    <Typography variant="h5" className={classes.title}>
                        Beautiful North Queensland
                    </Typography>
                    <Typography className={classes.body}>
                        Based in Cairns and Airlie Beach, GSL Aviation is lucky to explore some of the most amazing
                        landscapes in Australia. GSL explores more of this region than any other operator. In the
                        Whitsundays, the archipelago of islands are one of the most scenic regions in Australia, with
                        fringing reef and beaches such as Whitehaven Beach. In Cairns, you&apos;ll find secluded sand
                        cays, and the reef is right on your doorstep! As well as our daily scenic flights, our charter
                        services take us all the way to the Torres Strait, and the Gulf of Carpentaria.
                    </Typography>
                    <Hidden only="md">
                        <Typography className={classes.body}>
                            Book with GSL Aviation, and see the best of North Queensland from above. Travel from Cairns
                            or Airlie Beach to the Great Barrier Reef, tropical islands, and the beautiful coast of
                            Tropical North Queensland.
                        </Typography>
                    </Hidden>
                    <Typography className={classes.body}>
                        GSL Aviation also offer private charter in Cairns and the Whitsundays, with aircraft suited for
                        a variety of missions, including government/corporate charter, as well as bespoke tourism
                        products.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

/*

Collate and shuffle all product images from Product Store

const productController = useContext(ProductsStore);
const mediaList = shuffle(
    productController.products.map((prod) => prod.photos).reduce((acc, photos) => acc.concat(photos))
).map((image) => image.largeURL);

*/
