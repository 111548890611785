import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { BookingStore } from '../../../utils/stores/BookingStore';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { sum } from 'lodash';
import { availabilityStyles } from './availabilityStyles';
import CalendarDateSel from './CalendarDateSel';
import SessionSelect from './SessionSelect';
import InfoIcon from '@material-ui/icons/Info';
import { Skeleton } from '@material-ui/lab';

const BookingOptions = () => {
    const isMounted = IsMountedWrapper();
    const productController = useContext(ProductsStore);
    const bookingController = useContext(BookingStore);
    const classes = availabilityStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const sumOfFares = sum(
        bookingController.booking.quantities && bookingController.booking.quantities.map((x) => x.value)
    );

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        setAnchorEl(null);
    };

    const addFare = (index: number) => {
        const participants = (bookingController.booking.participants || []).concat({
            fields: productController.product.participantInfoFields.map((x) => x)
        });
        bookingController.setBooking(
            (prev) => ({
                ...prev,
                quantities: prev.quantities.map((q, i) => (i === index ? { ...q, value: q.value + 1 } : q)),
                participants: participants
            }),
            isMounted
        );
    };

    const minusFare = (index: number) => {
        const participants =
            bookingController.booking.participants.length > 1
                ? bookingController.booking.participants.slice(0, bookingController.booking.participants.length - 1)
                : [];
        bookingController.setBooking(
            (prev) => ({
                ...prev,
                quantities: prev.quantities.map((q, i) => (i === index ? { ...q, value: q.value - 1 } : q)),
                participants: participants
            }),
            isMounted
        );
    };

    const optionsArray = productController.product?.priceOptions;
    return (
        <Grid item className={classes.sectionItem}>
            <Button
                onClick={openMenu}
                className={classes.menuButton}
                style={{
                    borderColor: anchorEl ? theme.palette.primary.main : sumOfFares > 0 ? 'green' : '#0000003b',
                    color: sumOfFares > 0 ? 'green' : '#757575'
                }}
                endIcon={anchorEl ? <ExpandLessIcon /> : <ArrowDropDownIcon />}
                disableFocusRipple
            >
                {sumOfFares > 0 ? `Qty ${sumOfFares} Selected` : 'View Options'}
            </Button>
            <Menu
                id="fare-type-select"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                {optionsArray &&
                    optionsArray?.[0]?.label === bookingController.booking?.quantities?.[0]?.label &&
                    optionsArray.map((option, i) => {
                        const index =
                            bookingController.booking?.quantities &&
                            bookingController.booking?.quantities.findIndex((x) => x.label === option.label);
                        return (
                            <MenuItem key={`fare option ${i + 1}`} className={classes.menuItem}>
                                {index !== -1 && option.label && option.label.length > 0 ? (
                                    <>
                                        <ListItemText
                                            primary={
                                                option?.label.length > 17
                                                    ? option.label.substring(0, 18) + '...'
                                                    : option.label
                                            }
                                        />
                                        <IconButton
                                            aria-label="minus"
                                            disabled={bookingController.booking.quantities[index].value === 0}
                                            onClick={() => minusFare(index)}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        <Typography style={{ padding: '0 5px' }}>
                                            {bookingController.booking.quantities[index].value}
                                        </Typography>
                                        <IconButton
                                            aria-label="plus"
                                            disabled={
                                                bookingController.booking.quantities[index].value ===
                                                productController.product.maxQuantity
                                            }
                                            onClick={() => addFare(index)}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <Skeleton width={100} height={40} />
                                )}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </Grid>
    );
};

export default function CheckAvailability(props) {
    const classes = availabilityStyles();
    const paperRef = useRef<HTMLHeadingElement>(null);
    const [bookingHeight, setBookingHeight] = useState(0);
    const overlayBooking = useMediaQuery('(min-width: 1000px');
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const productController = useContext(ProductsStore);

    const calcHeight = () => {
        const height = paperRef.current && paperRef.current.offsetHeight;
        setBookingHeight(height);
    };

    useEffect(() => {
        calcHeight();
        window.addEventListener('resize', calcHeight);
        return () => {
            window.removeEventListener('resize', calcHeight);
        };
    }, []);

    useEffect(() => {
        calcHeight();
    }, [productController.product?.allowPackage]);

    return (
        <Grid container className={classes.container}>
            <Paper
                elevation={isXS ? 0 : 2}
                className={classes.paper}
                ref={paperRef}
                style={{
                    marginTop: overlayBooking ? `-${bookingHeight * 0.5}px` : isXS ? 10 : 30,
                    marginBottom: 30
                }}
            >
                <Grid container className={classes.innerContainer}>
                    <Grid item xs={12} sm={6} md={3} className={classes.innerGridItem}>
                        <Typography variant="h5" color="primary" className={classes.title}>
                            Date
                        </Typography>
                        <CalendarDateSel />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className={classes.innerGridItem}>
                        <Typography variant="h5" color="primary" className={classes.title}>
                            Time
                        </Typography>
                        <SessionSelect />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className={classes.innerGridItem}>
                        <Typography variant="h5" color="primary" className={classes.title}>
                            Fare Types
                        </Typography>
                        <BookingOptions />
                    </Grid>
                    <Grid container className={classes.buttonContainer} item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={props.handleClickOpen}
                        >
                            Book Now
                        </Button>
                    </Grid>
                </Grid>
                {productController.product?.allowPackage && (
                    <Box component="div" className={classes.packageNote}>
                        <InfoIcon color="primary" fontSize="large" style={{ marginRight: 10 }} />
                        <Typography className={classes.packageNoteText}>
                            Start by choosing a departure time for the{' '}
                            {productController.product.packageProductNames[0]} portion of your package tour.
                        </Typography>
                    </Box>
                )}
            </Paper>
        </Grid>
    );
}
