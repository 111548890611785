import { sum } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { WrappedUseState, WrappedUseStateReturn } from '../../functions/hooks';
import IsMountedWrapper from '../../functions/isMountedWrapper';
import { Booking } from '../../models/bookings';
import { Product } from '../../models/products';
import { WrappedSetter } from '../BaseStore';

interface Quantity {
    readonly quantity: number;
    readonly packageQuantity?: number;
}

export interface PickUpStepControllerValue {
    readonly resetPickUp: () => void;
    readonly resetPackagePickUp: () => void;
    readonly calcDisplayTime: (product: Product, isPackage: boolean) => string;
    readonly changePickUp: (event?: React.ChangeEvent<HTMLInputElement>, location?: string) => void;
    readonly changePackagePickUp: (event?: React.ChangeEvent<HTMLInputElement>, location?: string) => void;
    readonly togglePickUp: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readonly togglePackagePickUp: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readonly needPickUp: WrappedUseStateReturn<boolean>;
    readonly needPackagePickUp: WrappedUseStateReturn<boolean>;
    readonly pickUpQuantities: Quantity;
}

export default function PickUpStepController(
    booking: Booking,
    setBooking: WrappedSetter<Booking>,
    packageBooking: Booking,
    setPackageBooking: WrappedSetter<Booking>,
    product: Product,
    packageProduct: Product
): PickUpStepControllerValue {
    const isMounted = IsMountedWrapper();
    const needPickUp = WrappedUseState(false);
    const needPackagePickUp = WrappedUseState(false);
    const [pickUpQuantity, setQuantity] = useState(0);
    const [packagePickUpQuantity, setPackageQuantity] = useState(
        needPackagePickUp.data
            ? packageBooking?.quantities && sum(packageBooking?.quantities.map((q) => q.seatsUsed * q.value))
            : 0
    );

    useEffect(() => {
        booking.quantities?.[0]?.label &&
            setQuantity(
                needPickUp.data ? booking?.quantities && sum(booking.quantities.map((q) => q.seatsUsed * q.value)) : 0
            );
    }, [booking?.quantities]);

    useEffect(() => {
        packageBooking?.quantities?.[0]?.label &&
            setPackageQuantity(
                needPackagePickUp.data
                    ? packageBooking?.quantities && sum(packageBooking?.quantities.map((q) => q.seatsUsed * q.value))
                    : 0
            );
    }, [packageBooking?.quantities]);

    const calcPickUpTime = (product: Product, booking: Booking, isReset?: boolean) => {
        const offset = isReset
            ? product?.pickupList?.[0]?.minutesPrior
            : product.pickupList.find((x) => x.locationName === booking.tour.pickUpLocation)?.minutesPrior || 0;
        return moment(booking.tour.startTimeLocal).utcOffset(10).subtract(offset, 'm').toISOString(true);
    };

    const resetPickUp = () => {
        setBooking(
            (prev) => ({
                ...prev,
                tour: {
                    ...prev.tour,
                    pickUpTime: calcPickUpTime(product, booking, true),
                    pickUpLocation: product?.pickupList?.[0]?.locationName
                }
            }),
            isMounted
        );
    };

    const resetPackagePickUp = () => {
        setPackageBooking(
            (prev) => ({
                ...prev,
                tour: {
                    ...prev.tour,
                    pickUpTime: packageProduct?.pickupList && calcPickUpTime(packageProduct, packageBooking, true),
                    pickUpLocation: packageProduct?.pickupList?.[0]?.locationName || ''
                }
            }),
            isMounted
        );
    };

    const calcDisplayTime = (product: Product, isPackage: boolean) => {
        const index =
            product?.pickupList &&
            product.pickupList.find(
                (x) => x.locationName === (isPackage ? packageBooking.tour.pickUpLocation : booking.tour.pickUpLocation)
            );
        if (!index) {
            return 'TBA';
        } else {
            const isoTime = calcPickUpTime(product, isPackage ? packageBooking : booking);
            return moment(isoTime).utcOffset(10).format('h:mm a, on MMM Do');
        }
    };

    const changePickUp = (event?: React.ChangeEvent<HTMLInputElement>, location?: string) => {
        const locationName = event !== null ? event.target.value : location;
        setBooking(
            (prev) => ({
                ...prev,
                tour: {
                    ...prev.tour,
                    pickUpTime: calcPickUpTime(product, {
                        ...booking,
                        tour: { ...booking.tour, pickUpLocation: locationName }
                    }),
                    pickUpLocation: locationName
                }
            }),
            isMounted
        );
    };

    const changePackagePickUp = (event?: React.ChangeEvent<HTMLInputElement>, location?: string) => {
        const locationName = event !== null ? event.target.value : location;
        setPackageBooking(
            (prev) => ({
                ...prev,
                tour: {
                    ...prev.tour,
                    pickUpTime: calcPickUpTime(packageProduct, {
                        ...packageBooking,
                        tour: { ...packageBooking.tour, pickUpLocation: locationName }
                    }),
                    pickUpLocation: locationName
                }
            }),
            isMounted
        );
    };

    const togglePickUp = (event: React.ChangeEvent<HTMLInputElement>) => {
        needPickUp.setData(event.target.checked, isMounted);
        event.target.checked && setQuantity(sum(booking.quantities.map((q) => q.seatsUsed * q.value)));
        !event.target.checked && setQuantity(0);
        !event.target.checked && resetPickUp();
    };

    const togglePackagePickUp = (event: React.ChangeEvent<HTMLInputElement>) => {
        needPackagePickUp.setData(event.target.checked, isMounted);
        event.target.checked && setPackageQuantity(sum(packageBooking.quantities.map((q) => q.seatsUsed * q.value)));
        !event.target.checked && setPackageQuantity(0);
        !event.target.checked && resetPackagePickUp();
    };

    return {
        resetPickUp: resetPickUp,
        resetPackagePickUp: resetPackagePickUp,
        calcDisplayTime: calcDisplayTime,
        changePickUp: changePickUp,
        changePackagePickUp: changePackagePickUp,
        togglePickUp: togglePickUp,
        togglePackagePickUp: togglePackagePickUp,
        needPickUp: needPickUp,
        needPackagePickUp: needPackagePickUp,
        pickUpQuantities: {
            quantity: pickUpQuantity,
            packageQuantity: packagePickUpQuantity
        }
    };
}
