import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Contact from '../pages/Contact';
import Homepage from '../pages/Homepage';
import TermsAndConditions from '../pages/TermsAndConditions';
import ProductTemplate from '../components/products/ProductTemplate';
import PageNotFound from '../pages/PageNotFound';
import BookingConfirmationPage from '../pages/BookingConfirmationPage';
import AirlieTours from '../pages/AirlieTours';
import CairnsTours from '../pages/CairnsTours';
import FleetPage from '../pages/FleetPage';

export enum PATH_IDS {
    PRODUCT_NAME = 'productName',
    PRODUCT_LOCATION = 'productLocation',
    BOOKING_REF = 'ref'
}

export const routes = {
    products: (productLocation: string, productName: string) => `/${productLocation}/${productName}`,
    confirmation: (ref: string) => `/confirmation/${ref}`
};

export default function Pages() {
    return (
        <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/contactus" component={Contact} />
            <Route path="/termsandconditions" component={TermsAndConditions} />
            <Route path="/fleet" component={FleetPage} />
            <Route path="/airlie" exact component={AirlieTours} />
            <Route path="/cairns" exact component={CairnsTours} />
            <Route path={routes.confirmation(':' + PATH_IDS.BOOKING_REF)} exact component={BookingConfirmationPage} />
            <Route
                path={routes.products(':' + PATH_IDS.PRODUCT_LOCATION, ':' + PATH_IDS.PRODUCT_NAME)}
                exact
                component={ProductTemplate}
            />
            <Route path="/404" component={PageNotFound} />
            <Redirect to="/404" />
        </Switch>
    );
}
