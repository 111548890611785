import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { sum } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import IsMountedWrapper from '../../../utils/functions/isMountedWrapper';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { ProductsStore, SessionValue } from '../../../utils/stores/ProductsStore';
import { availabilityStyles } from './availabilityStyles';

interface SessionSelectProps {
    readonly isPackage?: boolean;
}

export default function SessionSelect({ isPackage }: SessionSelectProps) {
    const bookingController = useContext(BookingStore);
    const productController = useContext(ProductsStore);
    const classes = availabilityStyles();
    const isMounted = IsMountedWrapper();
    const [localTourDisplay, setLocalTourDisplay] = useState<string>(
        isPackage && (JSON.stringify({ time: bookingController.packageBooking.data.tour.startTimeLocal }) || '')
    );
    const showLoading = productController.isLoading;
    const showPackageLoading =
        productController.availability.packageSessions &&
        !productController.availability.packageSessions.find((s) =>
            moment(s.startTime).isSame(productController.availability?.packageDepartureDate, 'day')
        );
    const departureDate = isPackage
        ? productController.availability.packageDepartureDate
        : productController.availability.departureDate;
    const sessionArray = isPackage
        ? productController.availability.packageSessions
        : productController.availability.sessions;

    const handletimechange = (time: any) => {
        isPackage
            ? bookingController.packageBooking.setData(
                  (prev) => ({
                      ...prev,
                      tour: {
                          ...prev.tour,
                          startTimeLocal: moment(time).utcOffset(10).toISOString(true)
                      }
                  }),
                  isMounted
              )
            : bookingController.setBooking(
                  (prev) => ({
                      ...prev,
                      tour: {
                          ...prev.tour,
                          startTimeLocal: moment(time).utcOffset(10).toISOString(true)
                      }
                  }),
                  isMounted
              );
    };

    const changeTourTime = (event) => {
        if (event.target.value) {
            const res = JSON.parse(event.target.value);
            const { time } = res;
            handletimechange(time);
            setLocalTourDisplay(event.target.value);
        }
    };

    const removeUnavailable = (sessions: SessionValue[]) => {
        const cutOffTime = moment().add(
            Number(
                isPackage
                    ? productController.packageProduct.data.minimumNoticeMinutes
                    : productController.product.minimumNoticeMinutes
            ),
            'm'
        );
        const availSessions =
            sessions && isPackage
                ? sessions
                      .filter(
                          (session) =>
                              moment(session.startTime).isAfter(cutOffTime) &&
                              session.seatsRemaining >
                                  sum(
                                      bookingController.packageBooking.data.quantities.map((q) => q.seatsUsed * q.value)
                                  )
                      )
                      .map((session) => ({ ...session, startTime: session.startTime }))
                : sessions
                      .filter((session) => moment(session.startTime).isAfter(cutOffTime))
                      .map((session) => ({ ...session, startTime: session.startTime }));
        return availSessions;
    };

    const displayByDay = (date: string, sessions: SessionValue[]) => {
        const localSessions =
            sessions &&
            sessions.map((session) => ({
                ...session,
                startTime: moment(session.startTime).utcOffset(10).toISOString(true)
            }));
        const dailySessions =
            localSessions &&
            localSessions
                .filter((session) => moment(session.startTime).isSame(moment(date), 'day'))
                .map((session) => ({ ...session, startTime: session.startTime }));
        return dailySessions;
    };

    return (
        <>
            {(isPackage ? showPackageLoading : showLoading) ? (
                <Grid item xs={12} className={classes.sectionItem}>
                    <div className={classes.loadContainer}>
                        <CircularProgress
                            variant="indeterminate"
                            color="primary"
                            disableShrink
                            size={24}
                            thickness={4}
                        />
                    </div>
                </Grid>
            ) : (
                <Grid item xs={12} className={classes.sectionItem}>
                    <TextField
                        select
                        className={classes.selectFields}
                        style={{ width: isPackage && '100%' }}
                        value={localTourDisplay}
                        error={
                            isPackage && bookingController.customerErrors.packageDate === 'Check your departure time'
                        }
                        helperText={
                            isPackage &&
                            bookingController.customerErrors.packageDate === 'Check your departure time' &&
                            bookingController.customerErrors.packageDate
                        }
                        onChange={changeTourTime}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu
                            }
                        }}
                        variant="outlined"
                        disabled={showLoading}
                    >
                        <option value="">
                            {displayByDay(departureDate, sessionArray) &&
                            removeUnavailable(displayByDay(departureDate, sessionArray)).length === 0
                                ? 'No Availability'
                                : 'Select Time'}
                        </option>
                        {displayByDay(departureDate, sessionArray) &&
                            removeUnavailable(displayByDay(departureDate, sessionArray)).map((session) => {
                                const { seatsRemaining, startTime } = session;
                                if (session.seatsRemaining > 0) {
                                    return (
                                        <option value={JSON.stringify({ time: startTime })} key={session.id}>
                                            {`${moment(startTime)
                                                .utcOffset(10)
                                                .format('h:mm a')} - ${seatsRemaining} remaining`}
                                        </option>
                                    );
                                } else {
                                    return (
                                        <option value={JSON.stringify({ time: startTime })} key={session.id}>
                                            {startTime !== 'Invalid date'
                                                ? `${moment(startTime).utcOffset(10).format('h:mm a')} - Sold Out`
                                                : 'No Departures Available'}
                                        </option>
                                    );
                                }
                            })}
                    </TextField>
                </Grid>
            )}
        </>
    );
}
