import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './utils/theme';
import CssBaseline from '@material-ui/core/CssBaseline';

import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
import './plugins/slick/slick.min.css';
import './plugins/slick/slick-theme.min.css';

import TagManager from 'react-gtm-module';
import Header from './components/coreLayout/Header';
import Footer from './components/coreLayout/Footer';
import Pages from './utils/PageRouter';
import SnackbarContainer from './utils/SnackbarContainer';
import ReactGA from 'react-ga4';

export default function App() {
    const tagManagerArgs = { gtmId: 'GTM-T4CD2VT' };
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize('G-LL4XSCD7T2');

    const setChatIndex = () => {
        const style = document.createElement('style');
        const css = document.createTextNode('.purechat-launcher-frame {z-index: 5 !important}');
        style.appendChild(css);
        document.head.appendChild(style);
    };

    useEffect(() => {
        setChatIndex();
    }, []);

    return (
        <div className="App">
            <SnackbarContainer />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <Header />
                    <Pages />
                    <Footer />
                </Router>
            </ThemeProvider>
        </div>
    );
}
