import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, Typography, Container, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { ProductsStore } from '../../utils/stores/ProductsStore';
import moment from 'moment';
import { Product, ProductCategories } from '../../utils/models/products';
import { Skeleton } from '@material-ui/lab';
import { productURL } from '../../utils/functions/common';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { Fire } from 'mdi-material-ui';
import { productGridStyles } from './productGridStyles';
import clsx from 'clsx';
import { max } from 'lodash';

export interface ProductGridProps {
    readonly backgroundColor?: boolean;
    readonly tag?: string;
    readonly category?: ProductCategories;
}

export default function ProductDisplayGrid({ backgroundColor, tag, category }: ProductGridProps) {
    const classes = productGridStyles();
    const productStore = useContext(ProductsStore);
    const [widgetHeight, setWidgetHeight] = useState(0);
    const widgetRefs = useRef<any>([]);
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));

    const productsToDisplay = tag
        ? category
            ? productStore.products
                  .filter((prod) => prod.tags.includes(tag))
                  .filter((prod2) => prod2.category === category)
            : productStore.products.filter((prod) => prod.tags.includes(tag))
        : productStore.products.filter((prod) => prod.category === category);

    const numOfProds = productsToDisplay.length;

    const isOnSale = (code: string) => {
        const product = productStore.products.filter((prod) => prod.productCode === code)[0];
        const sale =
            product.sales &&
            product.sales.filter((sale) => moment().isAfter(sale.startDate) && moment().isBefore(sale.endDate));
        const salePrice = sale && sale.length !== 0 ? sale[0].fareTypes[0].price : null;
        return salePrice;
    };

    const assignRef = (element: any, i: number) => {
        widgetRefs.current[i] = element;
    };

    const findMaxHeight = () => {
        const maxHeight = max(widgetRefs.current.map((ref: { offsetHeight: number }) => ref.offsetHeight));
        setWidgetHeight(maxHeight as number);
        return maxHeight;
    };

    useEffect(() => {
        findMaxHeight();
        window.addEventListener('resize', findMaxHeight);
        return () => {
            window.removeEventListener('resize', findMaxHeight);
        };
    }, []);

    useEffect(() => {
        findMaxHeight();
    }, [widgetRefs.current.map((r: any) => r.offsetHeight)]);

    return (
        <div className={clsx(classes.background, { [classes.backgroundColor]: backgroundColor })}>
            <Container maxWidth="lg">
                <Grid
                    container
                    className={classes.popGrid}
                    style={{ margin: 'auto', maxWidth: isLg && numOfProds < 5 && numOfProds % 2 === 0 && 1000 }}
                >
                    {productStore.isLoading
                        ? Array.from(Array(3).keys()).map((_, i) => (
                              <Grid
                                  container
                                  justifyContent="center"
                                  style={{ paddingBottom: 20 }}
                                  item
                                  key={`skel${i}`}
                                  xs={12}
                                  sm={6}
                                  md={4}
                              >
                                  <Skeleton variant="rect" width="90%" height={500} />
                              </Grid>
                          ))
                        : productsToDisplay.map((product: Product, i: number) => {
                              const linkURL = productURL(product);
                              return (
                                  <Grid
                                      key={product.productCode}
                                      item
                                      xs={12}
                                      lg={numOfProds % 3 === 0 ? 4 : numOfProds < 5 && numOfProds % 2 === 0 ? 12 : 4}
                                      style={{ padding: 10 }}
                                  >
                                      <Paper elevation={1} style={{ borderRadius: 10, height: '100%' }}>
                                          <Grid container className={classes.gridContainer}>
                                              <Grid
                                                  item
                                                  xs={12}
                                                  md={4}
                                                  lg={numOfProds < 5 && numOfProds % 2 === 0 ? 4 : 12}
                                                  style={{
                                                      height:
                                                          (isMd || (isLg && numOfProds < 5 && numOfProds % 2 === 0)) &&
                                                          (widgetHeight || 0) + 62
                                                  }}
                                              >
                                                  <Link to={linkURL}>
                                                      <div
                                                          className={clsx(classes.imageCommon, {
                                                              [classes.imageLeft]:
                                                                  isMd ||
                                                                  (isLg && numOfProds < 5 && numOfProds % 2 === 0),
                                                              [classes.imageTop]:
                                                                  !isMd &&
                                                                  !(isLg && numOfProds < 5 && numOfProds % 2 === 0)
                                                          })}
                                                      >
                                                          <img
                                                              className={classes.featureImage}
                                                              src={product?.photos?.[0]?.mediumURL}
                                                              alt={product?.photos?.[0]?.alt}
                                                              style={{
                                                                  height:
                                                                      isLg &&
                                                                      numOfProds < 5 &&
                                                                      numOfProds % 2 === 0 &&
                                                                      '100%'
                                                              }}
                                                          />
                                                      </div>
                                                  </Link>
                                              </Grid>
                                              <Grid
                                                  item
                                                  xs={true}
                                                  container
                                                  direction="column"
                                                  justifyContent="space-between"
                                              >
                                                  <Box style={{ height: widgetHeight || 0 }}>
                                                      <Grid
                                                          item
                                                          className={classes.featureDescription}
                                                          style={{
                                                              paddingBottom:
                                                                  isLg && numOfProds < 5 && numOfProds % 2 === 0 && 15,
                                                              minHeight:
                                                                  isLg &&
                                                                  numOfProds < 5 &&
                                                                  numOfProds % 2 === 0 &&
                                                                  'unset'
                                                          }}
                                                          ref={(el) => assignRef(el, i!)}
                                                      >
                                                          <Typography
                                                              variant="h5"
                                                              color="primary"
                                                              className={classes.title}
                                                          >
                                                              {product.name}
                                                          </Typography>
                                                          <Typography className={classes.body}>
                                                              {product.shortDescription}
                                                          </Typography>
                                                          <Box
                                                              component="div"
                                                              style={{ display: 'flex', flexWrap: 'wrap' }}
                                                          >
                                                              {isOnSale(product.productCode) && (
                                                                  <Box className={classes.onSale}>
                                                                      <LoyaltyIcon
                                                                          style={{ marginRight: 5, padding: 1 }}
                                                                      />
                                                                      On Sale!
                                                                  </Box>
                                                              )}
                                                              {product.saleTips &&
                                                                  product.saleTips.map((tip) => (
                                                                      <Box key={tip} className={classes.onSale}>
                                                                          <Fire
                                                                              style={{ marginRight: 5, padding: 1 }}
                                                                          />
                                                                          {tip}
                                                                      </Box>
                                                                  ))}
                                                          </Box>
                                                      </Grid>
                                                  </Box>
                                                  <Grid item className={classes.priceOffer}>
                                                      {isOnSale(product.productCode) ? (
                                                          <span style={{ display: 'flex', alignItems: 'center' }}>
                                                              <Typography
                                                                  variant="h5"
                                                                  style={{ textDecoration: 'line-through' }}
                                                              >
                                                                  ${product?.displayPrice?.price}
                                                              </Typography>
                                                              <Typography variant="h5" style={{ marginLeft: 10 }}>
                                                                  ${isOnSale(product.productCode)}
                                                              </Typography>
                                                          </span>
                                                      ) : (
                                                          <span style={{ display: 'flex', alignItems: 'center' }}>
                                                              <Typography variant="h5">
                                                                  ${product?.displayPrice?.price}
                                                              </Typography>
                                                          </span>
                                                      )}
                                                      <Link to={linkURL}>
                                                          <Button
                                                              variant="contained"
                                                              color="primary"
                                                              className={classes.featureButton}
                                                          >
                                                              Book Now
                                                          </Button>
                                                      </Link>
                                                  </Grid>
                                              </Grid>
                                          </Grid>
                                      </Paper>
                                  </Grid>
                              );
                          })}
                </Grid>
            </Container>
        </div>
    );
}
