import { Box, Container, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { FleetImage, FleetItem } from '../../pages/FleetPage';
import FleetImageDisplay from './ImageItem';

interface FleetItemDisplayProps {
    readonly asset: FleetItem;
    readonly reverse?: boolean;
}

const fleetItemStyles = makeStyles((theme) => ({
    outerContainer: {
        [theme.breakpoints.only('md')]: {
            paddingLeft: '10%',
            paddingRight: '10%'
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 15
        },
        paddingTop: 20
    },
    title: {
        color: theme.palette.primary.dark,
        marginBottom: 20,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 5
        }
    },
    gridReverse: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    imgMarginLeft: {
        marginLeft: 30,
        [theme.breakpoints.down('md')]: {
            margin: 0
        }
    },
    imgMarginRight: {
        marginRight: 30,
        [theme.breakpoints.down('md')]: {
            margin: 0
        }
    },
    imageBox: {
        width: 500,
        [theme.breakpoints.down('sm')]: {
            marginTop: 10
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        borderRadius: 10,
        overflow: 'hidden',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            borderRadius: 0
        }
    },
    arrow: {
        position: 'absolute',
        opacity: 0.5,
        transform: 'translate(0, -50%)',
        top: '50%',
        backgroundColor: 'white',
        '& .MuiSvgIcon-root': {
            fontSize: '1.5rem'
        },
        padding: '0.35rem',
        [theme.breakpoints.only('xs')]: {
            backgroundColor: 'unset',
            color: 'white',
            padding: 0,
            '& .MuiSvgIcon-root': {
                fontSize: '1.8rem'
            }
        }
    },
    arrowLeft: {
        left: '1rem',
        [theme.breakpoints.only('xs')]: {
            left: 5
        },
        [theme.breakpoints.up('lg')]: {
            left: 12
        }
    },
    arrowRight: {
        right: '1rem',
        [theme.breakpoints.only('xs')]: {
            right: 5
        },
        [theme.breakpoints.up('lg')]: {
            right: 12
        }
    },
    description: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 16,
            paddingRight: 16
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 25
        }
    },
    descriptionText: {
        textAlign: 'justify',
        color: theme.palette.text.soft,
        marginBottom: 15,
        marginTop: 10,
        [theme.breakpoints.only('xs')]: {
            lineHeight: 1.35,
            marginBottom: 10
        }
    }
}));

export default function FleetItemDisplay({ asset, reverse }: FleetItemDisplayProps) {
    const classes = fleetItemStyles();
    const [currentImage, setCurrentImage] = useState(0);
    const length = asset.images.length;
    const [imageHeight, setImageHeight] = useState(0);
    const imageRef = useRef<HTMLHeadingElement>(null);
    const [slide, setSlide] = useState(false);
    const [slideDirection, setSlideDirection] = useState('left');

    const calcHeight = () => {
        const height = imageRef.current && imageRef.current.offsetWidth * 0.5625;
        setImageHeight(height);
    };

    useEffect(() => {
        calcHeight();
        window.addEventListener('resize', calcHeight);

        return () => {
            window.removeEventListener('resize', calcHeight);
        };
    }, []);

    useEffect(() => {
        setSlide(true);
    }, [currentImage]);

    const handleNext = () => {
        setSlideDirection('right');
        setSlide(false);

        setTimeout(() => {
            setCurrentImage(currentImage === length - 1 ? 0 : currentImage + 1);
            setSlideDirection('left');
            setSlide(true);
        }, 300);
    };

    const handlePrev = () => {
        setSlideDirection('left');
        setSlide(false);

        setTimeout(() => {
            setCurrentImage(currentImage === 0 ? length - 1 : currentImage - 1);
            setSlideDirection('right');
            setSlide(true);
        }, 300);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const textDisplay = (text: string) => {
        const sentences = text.match(/[^.!?]+[.!?"']+["']?/g);
        const output = Array.from(Array(sentences.length).keys()).map((_, i) =>
            i % 2 === 0 ? [sentences[i], sentences[i + 1] && sentences[i + 1]] : ['']
        );
        return (
            <>
                {output.map((s, i) => (
                    <Typography key={i} className={classes.descriptionText}>
                        {s}
                    </Typography>
                ))}
            </>
        );
    };

    return (
        <Container maxWidth="lg" className={classes.outerContainer}>
            <Typography variant="h3" className={classes.title}>
                {asset.name}
            </Typography>
            <Grid container className={clsx({ [classes.gridReverse]: reverse })}>
                <Grid item xs={12} lg="auto">
                    <Box
                        className={clsx(classes.imageBox, {
                            [classes.imgMarginLeft]: reverse,
                            [classes.imgMarginRight]: !reverse
                        })}
                        {...handlers}
                        style={{ height: imageHeight }}
                    >
                        <div style={{ width: '100%', height: imageHeight }} ref={imageRef}>
                            {asset.images.map((image: FleetImage, i: number) => (
                                <Box key={`image${i + 1}`}>
                                    {i === currentImage && (
                                        <FleetImageDisplay
                                            image={image}
                                            height={imageHeight}
                                            slide={slide}
                                            slideDirection={slideDirection}
                                        />
                                    )}
                                </Box>
                            ))}
                        </div>
                        <IconButton onClick={() => handleNext()} className={clsx(classes.arrow, classes.arrowRight)}>
                            <Tooltip title="Next Image">
                                <ChevronRightIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => handlePrev()} className={clsx(classes.arrow, classes.arrowLeft)}>
                            <Tooltip title="Previous Image">
                                <ChevronLeftIcon />
                            </Tooltip>
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12} md={true} style={{ paddingTop: 10 }}>
                    <Box className={classes.description}>{textDisplay(asset.description)}</Box>
                </Grid>
            </Grid>
        </Container>
    );
}
