import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { isInteger, sum } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { amountUiStr } from '../../../utils/functions/common';
import { Extras } from '../../../utils/models/products';
import { BookingStore } from '../../../utils/stores/BookingStore';
import { ProductsStore } from '../../../utils/stores/ProductsStore';
import { defaultRenderInput } from '../../coreLayout/defaultAutocompleteInput';
import { commonStyles } from './commonStyles';

interface ExtraSelectionContainerProps {
    readonly extra: Extras;
    readonly fixQuantity?: number;
}

export function ExtraSelectionContainer({ extra, fixQuantity }: ExtraSelectionContainerProps) {
    const bookingController = useContext(BookingStore);
    const classes = commonStyles();
    const imageExists = extra?.image;
    const currentExtra = bookingController.booking.extras.find((e) => e.id === extra.id);
    const MAX_QUANTITY = sum(bookingController.booking.quantities.map((q) => q.value)) + 1;

    useEffect(() => {
        bookingController.addExtraToBooking(extra);
    }, []);

    useEffect(() => {
        isInteger(fixQuantity) && bookingController.handleSelectedExtraChange(currentExtra, fixQuantity);
    }, [fixQuantity]);

    return (
        <Grid container style={{ width: '100%', marginBottom: 25 }}>
            {imageExists && (
                <Hidden only="xs">
                    <Grid item xs={12} sm="auto" className={classes.extraItemImage}>
                        <img
                            src={extra.image.thumbnailURL}
                            alt={extra.image.alt}
                            style={{ height: 110, width: 110, borderRadius: 10, objectFit: 'cover' }}
                        />
                    </Grid>
                </Hidden>
            )}
            <Grid item xs={12} sm={true} className={classes.extraItemText}>
                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className={classes.extraTitle}>{extra.name}</Typography>
                </span>
                <Typography className={classes.extraDescription}>{extra.description}</Typography>
            </Grid>
            <Grid item xs={12} sm="auto" className={classes.extraItemQuantity}>
                <Grid
                    item
                    className={classes.extraQuantityInner}
                    style={{ justifyContent: isInteger(fixQuantity) && 'flex-end' }}
                >
                    <Box style={{ display: 'flex' }}>
                        <Hidden smUp>
                            {isInteger(fixQuantity) && (
                                <>
                                    <Typography
                                        className={classes.quantityTitle}
                                        style={{ fontWeight: 300, paddingRight: 10 }}
                                    >
                                        Price per person:
                                    </Typography>
                                </>
                            )}
                        </Hidden>
                        <Typography className={classes.quantityTitle}>
                            $ {(extra.price / 100) % 1 !== 0 ? amountUiStr(extra.price / 100) : extra.price / 100}
                        </Typography>
                    </Box>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        {!isInteger(fixQuantity) && (
                            <>
                                <Hidden smUp>
                                    <Typography
                                        className={classes.quantityTitle}
                                        style={{ fontWeight: 300, paddingRight: 15 }}
                                    >
                                        Select Qty.
                                    </Typography>
                                </Hidden>
                                <Autocomplete
                                    disableClearable
                                    style={{ width: 72 }}
                                    value={currentExtra?.quantity || 0}
                                    onChange={(_, value) => {
                                        const quantity = Number(value);
                                        bookingController.handleSelectedExtraChange(currentExtra, quantity);
                                    }}
                                    onInputChange={(_, value) => {
                                        const quantity = Number(value);
                                        if (quantity >= 0 && quantity <= MAX_QUANTITY) {
                                            bookingController.handleSelectedExtraChange(currentExtra, quantity);
                                        }
                                    }}
                                    getOptionLabel={(option) => option.toString()}
                                    options={Array.from(Array(MAX_QUANTITY).keys()).map((q) => q)}
                                    renderInput={defaultRenderInput}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default function ExtraSelectionStep() {
    const classes = commonStyles();
    const productController = useContext(ProductsStore);

    return (
        <Grid container className={clsx(classes.container, classes.overflow)}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                    Extras
                </Typography>
                <Typography variant="body2" className={classes.subtitle}>
                    Select quantity to add items to your booking. The maximum quantity is determined by the number of
                    fares or booking options selected.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {productController.product.extras.map((extra, i) => (
                    <Box key={`extraOption${i}`}>
                        {extra.id !== 'Bus Transfer' && <ExtraSelectionContainer extra={extra} />}
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
}
